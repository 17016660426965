import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function modifyIntervention(intervention) {
  return new Promise(async (resolve, reject) => {
    try {
      const req = await fetch(`${apiUrl}/api/updateIntervention`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(intervention),
      });

      // If the token is invalid
      const result = await handleApiResponse(
          req,
          () => modifyIntervention(intervention),
          false 
      );
      resolve(result);
    } catch (error) {
      // Handle any other errors
      reject(error.message);
    }
  });
}
