import {handleApiResponse} from "../../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function fetchDetailsActivityByDomainByUserAndSchoolYear(
    userId,
    schoolYear,
    domainsId,
    typeOfTotal
) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!Array.isArray(domainsId)) {
                domainsId = [domainsId];
            }
            domainsId = domainsId.join(",");

            const req = await fetch(
                `${apiUrl}/api/getDetailsNumberOfHourPerDomaineByUserAndSchoolYear?
                userId=${userId}&
                schoolYear=${schoolYear.anneeScolaire}&
                domainsId=${domainsId}&
                typeOfTotal=${typeOfTotal}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            // If the token is invalid
            const result = await handleApiResponse(
                req,
                () => fetchDetailsActivityByDomainByUserAndSchoolYear(
                    userId,
                    schoolYear,
                    domainsId,
                    typeOfTotal
                ),
                true
            );
            resolve(result);
        } catch (error) {
            // Handle any other errors
            reject(error.message);
        }
    });
}
