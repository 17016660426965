import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  DialogActions,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import * as PropTypes from "prop-types";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { checkPassword } from "./api_requests/checkPassword";
import { updatePassword } from "./api_requests/updatePassword";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// css style
import "../../style/UpdatePassword.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "50%",
    maxWidth: "none",
  },
}));

InputAdornment.propTypes = {
  position: PropTypes.string,
  children: PropTypes.node,
};
export default function UpdatePassword(props) {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);
  const [atLeastOneNumber, setAtLeastOneNumber] = useState(false);
  const [atLeastOneUppercase, setAtLeastOneUppercase] = useState(false);
  const [atLeastOneLowercase, setAtLeastOneLowercase] = useState(false);
  const [atLeastEightCharacters, setAtLeastEightCharacters] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [emptyField, setEmptyField] = useState(false);

  const navigate = useNavigate();
  const selectedUserIsCurrentUser = props.selectedUserIsCurrentUser;
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // reset all states
    resetAllStates();
  };

  const verifyCurrentPassword = () => {
    setWrongCurrentPassword(false);
    setEmptyField(false);

    if (currentPassword === "") {
      setEmptyField(true);
      return;
    }
    const currentUser = JSON.parse(localStorage.getItem("user"));

    // fetch api to verify the current password
    checkPassword(currentUser.mail, currentPassword, navigate)
      .then((response) => {
        if (response.status === 200) {
          setPasswordValid(true);
        } else {
          setWrongCurrentPassword(true);
          setPasswordValid(false);
        }
      })
      .catch((error) => {
        console.error("Error during password verification:", error);
        setPasswordValid(false);
      });
  };

  function resetAllStates() {
    setPasswordValid(false);
    setPasswordMismatch(false);
    setAtLeastOneLowercase(false);
    setAtLeastOneUppercase(false);
    setAtLeastOneNumber(false);
    setAtLeastEightCharacters(false);
    setNewPassword("");
    setConfirmPassword("");
    setCurrentPassword("");
    setWrongCurrentPassword(false);
    setSuccessUpdate(false);
    setErrorUpdate(false);
    setOpen(false);
    setEmptyField(false);
  }

  const changePassword = () => {
    // reset all states to have a custom error message for each case
    setPasswordMismatch(false);
    setAtLeastOneLowercase(false);
    setAtLeastOneUppercase(false);
    setAtLeastOneNumber(false);
    setAtLeastEightCharacters(false);
    setErrorUpdate(false);
    setSuccessUpdate(false);
    setEmptyField(false);

    if (newPassword === "" || confirmPassword === "") {
      setEmptyField(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }

    // check if the new password have at least 8 characters
    if (newPassword.length < 8) {
      setAtLeastEightCharacters(true);
      return;
    }

    // check if the new password contains at least one number
    if (!/\d/.test(newPassword)) {
      setAtLeastOneNumber(true);
      return;
    }

    // check if the new password contains at least one uppercase letter
    if (!/[A-Z]/.test(newPassword)) {
      setAtLeastOneUppercase(true);
      return;
    }

    // check if the new password contains at least one lowercase letter
    if (!/[a-z]/.test(newPassword)) {
      setAtLeastOneLowercase(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }

    // fetch api to update the password
    updatePassword(newPassword, navigate)
      .then((response) => {
        if (response.status === 200) {
          setSuccessUpdate(true);

          // wait 1.5s before closing the dialog
          setTimeout(() => {
            handleClose();
          }, 2500);
        } else {
          setErrorUpdate(true);
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la mise à jour de votre mot de passe.");
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!passwordValid) {
        verifyCurrentPassword();
      } else {
        changePassword();
      }
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="text"
        disabled={!selectedUserIsCurrentUser}
        onClick={handleClickOpen}
        sx={{
          color: "blue",
          fontSize: "0.9rem",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        Mettre à jour votre mot de passe
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography>
            <b>Mettre à jour votre mot de passe</b>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "red",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className={"form-change-password"}>
            {/* The user needs to enter his current password to update his password */}
            {!passwordValid && (
              <>
                <div className={"text-field-dialog"}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    <b>Votre mot de passe actuel : </b>
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>

                {emptyField && (
                  <Typography color="error">
                    <b>Veuillez remplir tous les champs.</b>
                  </Typography>
                )}
                {wrongCurrentPassword && (
                  <Typography color="error">
                    <b>Mot de passe incorrect, veuillez réessayer.</b>
                  </Typography>
                )}
              </>
            )}

            {/* When the user's current password is verified, they can update their password */}
            {passwordValid && (
              <>
                <div className={"text-field-dialog"}>
                  <InputLabel htmlFor="outlined-adornment-new-password">
                    <b>Nouveau mot de passe : </b>
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-new-password"
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className={"text-field-dialog"}>
                  <InputLabel htmlFor="outlined-adornment-confirm-password">
                    <b>Confirmer le mot de passe :</b>
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onPaste={(e) => e.preventDefault()}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>

                {/* Password validation feedback */}
                {emptyField && (
                  <Typography color="error">
                    <b>Veuillez remplir tous les champs.</b>
                  </Typography>
                )}
                {passwordMismatch && (
                  <Typography color="error">
                    <b>
                      Les mots de passe ne correspondent pas, veuillez
                      réessayer.
                    </b>
                  </Typography>
                )}
                {atLeastEightCharacters && (
                  <Typography color="error">
                    <b>Le mot de passe doit contenir au moins 8 caractères.</b>
                  </Typography>
                )}
                {atLeastOneNumber && (
                  <Typography color="error">
                    <b>Le mot de passe doit contenir au moins un chiffre.</b>
                  </Typography>
                )}
                {atLeastOneUppercase && (
                  <Typography color="error">
                    <b>
                      Le mot de passe doit contenir au moins une lettre
                      majuscule.
                    </b>
                  </Typography>
                )}
                {atLeastOneLowercase && (
                  <Typography color="error">
                    <b>
                      Le mot de passe doit contenir au moins une lettre
                      minuscule.
                    </b>
                  </Typography>
                )}
                {successUpdate && (
                  <Typography color="green">
                    <b>Mot de passe mis à jour avec succès !</b>
                  </Typography>
                )}
                {errorUpdate && (
                  <Typography color="error">
                    <b>
                      Erreur lors de la mise à jour de votre mot de passe,
                      veuillez réessayer.
                    </b>
                  </Typography>
                )}
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={passwordValid ? changePassword : verifyCurrentPassword}
          >
            {passwordValid ? "Mettre à jour" : "Vérifier"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
