// P:/Boulot/ETIC/GCU/client/src/utils/errorHandler.js
import { verifyToken } from "../../../client/src/utils/utils.js";
export const handleApiResponse = async (req, retryFunction, needFullResponse = false) => {
    if (req.status === 401) {
        console.log("Token invalide");
        const token = await verifyToken();
        if(token) {
            return retryFunction();
        }
        else {
            console.log("auto deco");
            window.location.href = "/";
        }
    }

    if (req.status === 403) {
        throw new Error("Vous n'êtes pas autorisé à effectuer cette action");
    }

    const response = await req.json();

    if (req.status === 200) {
        return needFullResponse ? response : response.success;
    } else {
        throw new Error(response.error);
    }
};

