import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchParcours } from "../Shared/CallAPI/Fetch/fetchParcours";
import { fetchPromotion } from "../Shared/CallAPI/Fetch/fetchPromotion";
import { fetchSemestre } from "../Shared/CallAPI/Fetch/fetchSemestre";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { fetchAnnees } from "../Shared/CallAPI/Fetch/fetchAnnees";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";

import "../../style/Dashboard.css";

export default function FiltresPopUp({ open, setOpen }) {
  const [parcoursData, setParcoursData] = useState([]);
  const [promotionData, setPromotionData] = useState([]);
  const [semestreData, setSemestreData] = useState([]);
  const [anneeData, setAnneeData] = useState([]);
  const [filters, setFilters] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const filtersDashboard = localStorage.getItem("filtersDashboard");
    if (filtersDashboard) {
      setFilters(JSON.parse(filtersDashboard));
    }
  }, [navigate]);

  useEffect(() => {
    if (filters.length > 0)
      localStorage.setItem("filtersDashboard", JSON.stringify(filters));
  }, [filters]);

  const handleClose = () => {
    setOpen(false);
  };

  const addFilter = (index) => {
    setFilters([
      ...filters,
      {
        id: filters.length,
        parcours: null,
        promotion: null,
        semestre: null,
        annee: null,
      },
    ]);
  };

  const updateFilter = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;
    setFilters(newFilters);
    if (filters.length > 0)
      localStorage.setItem("filtersDashboard", JSON.stringify(filters));
  };

  const removeFilter = (index) => {
    const newFilters = filters
      .filter((_, i) => i !== index)
      .map((filter, i) => ({ ...filter, id: i }));

    setFilters(newFilters);
    localStorage.setItem("filtersDashboard", JSON.stringify(filters));
  };

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        const parcoursResp = await fetchParcours();
        setParcoursData(parcoursResp);

        const promotionResp = await fetchPromotion();
        setPromotionData(promotionResp);

        const semestreResp = await fetchSemestre();
        setSemestreData(semestreResp);

        const anneeResp = await fetchAnnees();
        setAnneeData(anneeResp);
      };
      fetchData();
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        maxWidth={"100%"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            localStorage.setItem("filtersDashboard", JSON.stringify(filters));
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Filtres</DialogTitle>
        <DialogContent>
          <Box className="dialog-content">
            <DialogContentText>Veuillez choisir les filtres</DialogContentText>
            {filters.map((filter, index) => (
              <Box
                key={index}
                sx={{ display: "flex", gap: "1vw", alignItems: "center" }}
              >
                <Autocomplete
                  options={promotionData}
                  getOptionLabel={(option) => option.nomPromotion}
                  renderInput={(params) => (
                    <TextField {...params} label="Promotion" />
                  )}
                  value={filter.promotion}
                  onChange={(event, newValue) =>
                    updateFilter(index, "promotion", newValue)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                />
                <Autocomplete
                  options={parcoursData}
                  getOptionLabel={(option) => option.nomParcour}
                  renderInput={(params) => (
                    <TextField {...params} label="Parcours" />
                  )}
                  value={filter.parcours}
                  onChange={(event, newValue) =>
                    updateFilter(index, "parcours", newValue)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                />

                <Autocomplete
                  options={semestreData}
                  getOptionLabel={(option) => option.nomSemestre}
                  renderInput={(params) => (
                    <TextField {...params} label="Semestre" />
                  )}
                  value={filter.semestre}
                  onChange={(event, newValue) =>
                    updateFilter(index, "semestre", newValue)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                />
                <Autocomplete
                  options={anneeData}
                  getOptionLabel={(option) => option.anneeScolaire}
                  renderInput={(params) => (
                    <TextField {...params} label="Années" />
                  )}
                  value={filter.annee || null}
                  onChange={(event, newValue) => {
                    updateFilter(index, "annee", newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.anneeScolaire === value?.anneeScolaire
                  }
                  fullWidth
                />
                <IconButton onClick={() => removeFilter(index)}>
                  <Tooltip title="Supprimer le filtre">
                    <DeleteIcon color="error" />
                  </Tooltip>
                </IconButton>
              </Box>
            ))}
            <Button onClick={addFilter}>Ajouter un filtre</Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Annuler
          </Button>
          <Button type="submit">Appliquer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
