import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";

// Styled Paper component to act as a container
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "var(--bg-100)",
  maxWidth: "none",
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export default function ProfilBox(props) {
  const [userInfo, setUserInfo] = React.useState(null);
  const selectedUser = props.selectedUser;

  React.useEffect(() => {
    setUserInfo(selectedUser);
  }, [selectedUser]);

  // Helper function to safely access nested properties
  const getSafeProperty = (obj, prop, defaultValue = "inconnu") => {
    if (obj && typeof obj === "object") {
      return obj[prop] !== undefined ? obj[prop] : defaultValue;
    }
    return defaultValue;
  };

  return (
    <StyledPaper>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {userInfo ? (
          <b>
            {userInfo.name} {userInfo.surname}
          </b>
        ) : (
          "Profil"
        )}
      </Typography>
      <Divider sx={{ width: "90%" }} />
      {userInfo ? (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body1">
            <b>Email :</b> {getSafeProperty(userInfo, "mail")}
          </Typography>
          <Typography variant="body1">
            <b>Tel :</b> {getSafeProperty(userInfo, "phoneNumber") || "inconnu"}
          </Typography>

          {/* Check if user type is available and handle different formats */}
          {userInfo.type &&
            getSafeProperty(userInfo.type, "nom")
              .toLowerCase()
              .includes("vacataire") && (
              <Typography variant="body1">
                <b>Etat du dossier :</b>{" "}
                {getSafeProperty(userInfo.fileStatus, "nom")}
              </Typography>
            )}
          <Typography variant="body1">
            <b>Rôle :</b>{" "}
            {typeof userInfo.role === "object"
              ? getSafeProperty(userInfo.role, "nom")
              : userInfo.role}
          </Typography>
          <Typography variant="body1">
            <b>Type :</b>{" "}
            {typeof userInfo.type === "object"
              ? getSafeProperty(userInfo.type, "nom")
              : userInfo.type}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1">
          Aucune information utilisateur disponible.
        </Typography>
      )}
    </StyledPaper>
  );
}
