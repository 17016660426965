import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { tradFrGrid } from "../Shared/TxtFr";

export default function GridBoardFiche({ data }) {
    const [rows, setRows] = useState([]);

    const columns = [
        {
            field: "promotion",
            headerName: "Promotion",
            width: 150,
            editable: false,
            align: "center",
        },
        {
            field: "parcours",
            headerName: "Parcours",
            width: 150,
            editable: false,
            align: "center",
        },
        {
            field: "semestre",
            headerName: "Semestre",
            width: 150,
            editable: false,
            align: "center",
        },
        {
            field: "annee",
            headerName: "Année",
            width: 150,
            editable: false,
            align: "center",
        },
        {
            field: "CM_Fiche",
            headerName: "CM FaF",
            type: "number",
            width: 110,
            editable: false,
            renderCell: (params) => (
                <span>
                    <span style={{ fontWeight: 'bold' }}>
                        {params.value}
                    </span>
                    {params.row.ratioCM && (
                        <span>
                            {` (${params.row.ratioCM}%)`}
                        </span>
                    )}
                </span>
            ),
        },
        {
            field: "TD_Fiche",
            headerName: "TD FaF",
            type: "number",
            editable: false,
            width: 110,
            renderCell: (params) => (
                <span>
                    <span style={{ fontWeight: 'bold' }}>
                        {params.value}
                    </span>
                    {params.row.ratioTD && (
                        <span>
                            {` (${params.row.ratioTD}%)`}
                        </span>
                    )}
                </span>
            ),
        },
        {
            field: "TP_Fiche",
            headerName: "TP FaF",
            type: "number",
            width: 110,
            editable: false,
            renderCell: (params) => (
                <span>
                    <span style={{ fontWeight: 'bold' }}>
                        {params.value}
                    </span>
                    {params.row.ratioTP && (
                        <span>
                            {` (${params.row.ratioTP}%)`}
                        </span>
                    )}
                </span>
            ),
        },
        {
            field: "Budget",
            headerName: "Budget total",
            type: "number",
            editable: false,
            width: 130,
            renderCell: (params) => (
                <span>
                    <span style={{ fontWeight: 'bold' }}>
                        {params.value}
                    </span>
                    {params.row.ratioTotal && (
                        <span>
                            {` (${params.row.ratioTotal}%)`}
                        </span>
                    )}
                </span>
            ),
        },
        {
            field: "ECTS",
            headerName: "ECTS total",
            type: "number",
            editable: false,
            width: 130,
        }
    ];

    // Add a strong tag to the header of each column
    columns.forEach((column) => {
        column.renderHeader = (params) => (
            <strong>{params.colDef.headerName}</strong>
        );
    });

    useEffect(() => {
        setRows(data);
    }, [data]);

    return (
        <Box className="grid-board">
            <DataGrid
                localeText={tradFrGrid}
                rows={rows}
                columns={columns}
                initialState={{
                    filter: {
                        filterModel: {
                            items: [
                                {
                                    columnField: "id",
                                    operatorValue: "contains",
                                    value: "", // You can set a default filter value here if needed
                                },
                            ],
                        },
                    },
                }}
            />
        </Box>
    );
}
