import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchPromotion } from "../Shared/CallAPI/Fetch/fetchPromotion";
import { addPromotion } from "./api_requests/addPromotion";
import toast from "react-hot-toast";
import { updatePromotion } from "./api_requests/updatePromotion";
import Typography from "@mui/material/Typography";

export default function PromotionTable() {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPromotionName, setNewPromotionName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchPromotion()
        .then((data) => setRows(data))
        .catch((error) => console.error(error));
  }, [navigate]);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewPromotionName("");
  };

  const handleAddPromotion = async () => {
    if (newPromotionName.trim() === "") {
      toast.error("Le nom de la promotion est obligatoire");
      return;
    }

    try {
      const newPromotion = await addPromotion(navigate, newPromotionName);
      const newRow = {id: newPromotion.id, nomPromotion: newPromotion.promotion };
      setRows([...rows, newRow]);
      handleCloseDialog();
      toast.success("Nouvelle promotion ajoutée avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de l'ajout de la promotion");
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.nomPromotion.trim() === "") {
      throw new Error("Le nom de la promotion est obligatoire");
    }

    try {
      const promise = updatePromotion(navigate, newRow.id, newRow.nomPromotion)
          .then((msg) => {
            return msg;
          });

      const updatedRows = rows.map((row) => (row.id === newRow.id ? newRow : row));
      setRows(updatedRows);

      toast.promise(promise, {
        loading: "Mise à jour de la promotion...",
        success: (msg) => <Typography>{msg}</Typography>,
        error: (msg) => <Typography>{msg}</Typography>,
      });
      return newRow;
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de la promotion");
      console.error(error);
      return null;
    }
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    } else {
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleRowEditStop = (params, event) => {
    // Prevent the automatic row focus out from saving the row a second time
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleProcessRowUpdateError = (error) => {
    toast.error(error.message);
  };

  const columns = [
    {
      field: "nomPromotion",
      headerName: "Promotion",
      width: 160,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "MàJ",
      width: 70,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{ color: "primary.main" }}
                onClick={(event) => {
                  event.stopPropagation(); // Stop the row from losing focus
                  handleSaveClick(id)();
                }}
            />,
            <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                onClick={handleCancelClick(id)}
                color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
          />,
        ];
      },
    },
  ];

  columns.forEach((column) => {
    column.renderHeader = (params) => <strong>{params.colDef.headerName}</strong>;
  });

  return (
      <Box sx={{ maxWidth: "260px", height: "400px" }}>
        <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleOpenDialog}>
            Ajouter une promotion
          </Button>
        </Box>

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Ajouter une promotion</DialogTitle>
          <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Nom de la promotion"
                type="text"
                fullWidth
                variant="standard"
                value={newPromotionName}
                onChange={(e) => setNewPromotionName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleAddPromotion}>Ajouter</Button>
          </DialogActions>
        </Dialog>

        <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            hideFooterPagination
        />
      </Box>
  );
}
