import * as React from "react";
import Box from "@mui/material/Box";
import GridIntervenant from "./GridIntervenant";
import DrawerBar from "../Shared/DrawerBar";
import "../../style/Intervenants.css";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {Tab, Tabs} from "@mui/material";
import UpdateRoleGrid from "./UpdateRoleGrid";

export default function Intervenants() {
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const [role, setRole]  = useState({});

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    //if the user isn't Directeur or Administration
      setRole(role.nom)
      console.log(role.nom)
    if (role.nom !== "Directeur" && role.nom !== "Administration") {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
    }
  }, []);
  return (
    <Box className="container">
      <DrawerBar selected={"/intervenants"} />
        <Box className = 'container-navbar-grid'>
              <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="intervenant tabs"
              >
                  <Tab label="Gestion des utilisateurs" />
                    {role === "Directeur" && <Tab label="Gestion des droits" />}
              </Tabs>
          <Box className="content-box">
              {tabIndex === 0 && <GridIntervenant />}
              {(tabIndex === 1 && role === "Directeur")  && <UpdateRoleGrid />}
          </Box>
        </Box>
    </Box>
  );
}
