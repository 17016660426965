import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddUserToolBar } from "./AddUserToolBar";
import { fetchAllUsers } from "../Shared/CallAPI/Fetch/fetchAllUsers";
import { fetchType } from "../Shared/CallAPI/Fetch/fetchType";
import { fetchDossier } from "../Shared/CallAPI/Fetch/fetchDossier";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
import "../../style/Intervenants.css";
import { fetchRole } from "../Shared/CallAPI/Fetch/fetchRole";
import { tradFrGrid } from "../Shared/TxtFr";
import {updateUser} from "./api_requests/updateUser";

export default function GridIntervenant() {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();
  const [types, setTypes] = useState([]);
  const [dossiers, setDossiers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [processUsage, setProcessUsage] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchAllUsers()
      .then((users) => {
        setRows(users);
      })
      .catch((error) => console.log(error));
    fetchType()
      .then((typeResp) => {
        // Map to have only the name of the type
        const nomTypes = typeResp.map((type) => type.nomType);
        setTypes(nomTypes);
      })
      .catch((error) => console.log(error));
    fetchDossier(navigate)
      .then((dossierResp) => {
        setDossiers(dossierResp);
      })
      .catch((error) => console.log(error));
    fetchRole()
      .then((roleResp) => {
        setRoles(roleResp);
      })
      .catch((error) => console.log(error));
  }, [navigate]);

  // Reload the users list
  const newUserReload = () => {
    fetchAllUsers()
      .then((users) => setRows(users))
      .catch((error) => console.log(error));
  };

  // When user save the row
  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setIsEditing(false);
  };

  const updateRowIntervenant = async (newRow) => {
    setProcessUsage(processUsage + 1);
    const updatedRow = { ...newRow, isNew: false };
    const saveResult = await processRowSave(updatedRow);

    if (saveResult !== null) {
      const newRows = rows.map((row) =>
        row.id === newRow.id ? updatedRow : row
      );
      setRows(newRows);
      return updatedRow;
    }
    return null;
  };

  const processRowSave = async (newRow) => {
    let errors = false
    if (newRow.name === "") {
      toast.error("Le prénom de l'intervenant est obligatoire");
      
      return null;
    }

    if (newRow.surname === "") {
      toast.error("Le nom de l'intervenant est obligatoire");
      let errors = true      
      return null;
    }

    if (newRow.phoneNumber !== "" && newRow.phoneNumber !== null && !newRow.phoneNumber.match(/^[0-9]{10}$/)) {
      toast.error("Le numéro de téléphone doit être composé de 10 chiffres");
      let errors = true
      return null;
    }

    if (
      newRow.mail !== "" &&
      newRow.phoneNumber !== null && 
      !newRow.mail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
    ) {
      toast.error("L'adresse mail n'est pas valide");
      let errors = true
      return null;
    }

    if (!errors)
    {
      const promise =  updateUser(newRow).then((msg) => {
        return msg;
      });

      toast.promise(promise, {
        loading: "Mise à jour de l'intervenant...",
        success: (msg) => <b>{msg.succes}</b>,
        error: (msg) => <b>{msg.error}</b>,
      });
    }
    
  
  };

  // When user stop editing the row
  const handleRowEditStop = (params, event) => {
    // Prevent the default behavior of stopping the edit.
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setIsEditing(true); // Set the state to indicate a user is being edited
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // When user cancel the row update
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }

    setIsEditing(false);
  };

  // When user add a new row
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "idInsa",
      headerName: "Id",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "surname",
      headerName: "Nom",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Prénom",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mail",
      headerName: "Email",
      width: 250,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Contrat",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: types,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phoneNumber",
      headerName: "Téléphone",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fileStatus",
      headerName: "Dossier",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: dossiers,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Mise à jour",
      width: 100,
      cellClassName: "actions",
      headerAlign: "center",
      align: "center",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Tooltip
              title="Sauvegarder les modifications"
              placement="left"
              key="save"
            >
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{ color: "primary.main" }}
                onClick={handleSaveClick(id)}
              />
            </Tooltip>,
            <Tooltip
              title="Annuler les modifications"
              placement="right"
              key="cancel"
            >
              <GridActionsCellItem
                icon={<CancelIcon color="error" />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="error"
              />
            </Tooltip>,
          ];
        }

        return [
          <Tooltip title="Modifier l'intervenant" placement="left" key="edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  // Add strong title to each column
  columns.forEach((column) => {
    column.renderHeader = (params) => (
      <strong>{params.colDef.headerName}</strong>
    );
  });

  return (
    <Box className="data-grid-container">
      <DataGrid
        rows={rows}
        className="data-grid"
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={updateRowIntervenant}
        clipboardCopyCellDelimiter=";"
        localeText={tradFrGrid}
        slots={{
          toolbar: AddUserToolBar,
        }}
        slotProps={{
          toolbar: { types, roles, newUserReload },
          pagination: { labelRowsPerPage: "Lignes par pages" },
        }}
      />
    </Box>
  );
}
