import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/App.css";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import Connexion from "./pages/Connexion/Connexion";
import Home from "./pages/Home/Home";
import Fiches from "./pages/Fiches/Fiches";
import Comptes from "./pages/Comptes/Comptes";
import Intervenants from "./pages/Intervenants/Intervenants";
import Variables from "./pages/Variables/Variables";
import Dashboard from "./pages/Dashboard/Dashboard";

const theme = createTheme();
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Connexion />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/fiches" element={<Fiches />} />
            <Route exact path="/comptes" element={<Comptes />} />
            <Route exact path="/intervenants" element={<Intervenants />} />
            <Route exact path="/variables" element={<Variables />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
