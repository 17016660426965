import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function updateUserRole(user) {
    return new Promise(async (resolve, reject) => {
        try {
            const req = await fetch(`${apiUrl}/api/updateUserRole`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(user),
            });
            // If the token is invalid
            const result = await handleApiResponse(
                req,
                () => updateUserRole(user),
                true
            );
            resolve(result);
        } catch (error) {
            // Handle any other errors
            reject(error.message);
        }
    });
}
