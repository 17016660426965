import { verifyToken } from "../../../../utils/utils";
import toast from "react-hot-toast";
const apiUrl = process.env.REACT_APP_API_URL;
export async function fetchDossier(navigate) {
  const req = await fetch(`${apiUrl}/api/getEtatsDossiers`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  // If the token is invalid
  if (req.status === 401) {
    console.log("token invalid");
    const tokenValid = await verifyToken(toast);
    if (tokenValid) {
      return fetchDossier(navigate);
    }
    else (
        toast.error("Vous avez été déconnecté")
    )
  }

  if (req.status === 200) {
    let dossierReq = await req.json();
    dossierReq = dossierReq.map((role) => role.nomEtatDossier);
    return dossierReq;
  } else {
    toast.error("Erreur lors de la récupération des données");
  }

  return req;
}
