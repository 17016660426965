import { verifyToken } from "../../../../utils/utils";
import toast from "react-hot-toast";
import { handleApiResponse } from "../../../../utils/errorHandler";

const apiUrl = process.env.REACT_APP_API_URL;

export async function export_intervention(year) {
    return new Promise(async (resolve, reject) => {
        try {
            const req = await fetch(`${apiUrl}/api/export-csv?year=${year}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            // Si la réponse n'est pas OK, on gère les erreurs (s'il y a des erreurs)
            if (!req.ok) {
                const result = await handleApiResponse(req, export_intervention, true);
                console.log('Error in response:', result);
                reject(result);
            }

            // La réponse est un blob (CSV), donc on retourne le blob directement
            const blob = await req.blob();
            resolve({message : "Données téléchargées", data : blob}); // On retourne le blob

        } catch (error) {
            // Handle any other errors
            reject(error.message);
        }
    });
}
