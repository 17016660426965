import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BarChart } from "@mui/x-charts";

export default function DetailsDialog(props) {
  const { open, onClose, rowData, detailsData } = props;

  const name = rowData ? rowData.Nom + " " + rowData.Prenom : "";

  // Check if detailsData contains any relevant hours
  const hasDetails = Object.values(detailsData).some(
    (item) => item.totalCM > 0 || item.totalTD > 0 || item.totalTP > 0,
  );

  // Prepare data for BarChart
  const chartData = {
    categories: Object.keys(detailsData), // Domain names
    series: [
      {
        label: "CM",
        data: Object.values(detailsData).map((item) => item.totalCM),
      },
      {
        label: "TD",
        data: Object.values(detailsData).map((item) => item.totalTD),
      },
      {
        label: "TP",
        data: Object.values(detailsData).map((item) => item.totalTP),
      },
    ],
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>
        <b>{name}</b>
      </DialogTitle>
      <DialogContent dividers>
        {hasDetails ? (
          <div>
            <Typography variant="h6" gutterBottom>
              Détails des heures effectuées par domaine
            </Typography>
            <BarChart
              xAxis={[{ scaleType: "band", data: chartData.categories }]}
              series={chartData.series}
              width={550}
              height={300}
              barLabel={(item, _) => {
                if ((item.value ?? 0) > 0) {
                  const value = Number(item.value);
                  // Display integer values as whole numbers and float values with 2 decimal places
                  return Number.isInteger(value) ? value + "h" : value.toFixed(2) + "h";
                }
              }}
              borderRadius={5}
            />
          </div>
        ) : (
          <Typography>
            {name} n'a effectué aucune intervention dans les domaines spécifiés.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
