import {
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import DialogCreateFiche from "./dialogCreateFiche";
import { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import "../../style/Fiche.css";
import ExportButton from "../Shared/export_csv";
import Box from "@mui/material/Box";

export function EditToolbar(props) {
  const { schoolYears, selectedSchoolYear, setSelectedSchoolYear, isDirector } = props;
  const [openFiche, setOpenFiche] = React.useState(false);
    const [canExport, setCanExport] = useState(false);

    useEffect(() => {
        const role = JSON.parse(localStorage.getItem("user")).role;
        if (role.nom === "Directeur" || role.nom === "Administration")
        {
            setCanExport(true)
        }
    }, []);
  
  const handleClick = () => {
    setOpenFiche((prevOpenFiche) => !prevOpenFiche);
  };

  const handlePopUp = useCallback(
    (idFiche) => {
      setOpenFiche((prevOpenFiche) => !prevOpenFiche);
    },
    [setOpenFiche],
  );

  return (
    <GridToolbarContainer className="toolbar" >
      <Box sx = {{display : 'flex'}}>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled = {!isDirector}>
          Ajouter une fiche
        </Button>
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton/>
          {(canExport) && (
              <ExportButton year = {selectedSchoolYear?.anneeScolaire} />
          )}
      </Box>
            <Autocomplete
                size="small"
                disableClearable
                className="select-school-year-toolbar-fiche"
                options={schoolYears}
                getOptionLabel={(option) => option.anneeScolaire ?? ""}
                defaultValue={selectedSchoolYear}
                renderInput={(params) => (
                    <TextField {...params} label="Année scolaire" />
                )}
                value={selectedSchoolYear}
                onChange={(event, value) => setSelectedSchoolYear(value)}
            />
        
      <DialogCreateFiche
        openFiche={openFiche}
        handlePopUp={handlePopUp}
        data={null}
      />
    </GridToolbarContainer>
  );
}
