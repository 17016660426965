import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
const apiUrl = process.env.REACT_APP_API_URL;
export async function updatePassword(newPassword, navigate) {
  try {
    const req = await fetch(`${apiUrl}/api/change-password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ newPassword: newPassword }),
    });

    if (req.status === 401) {
      console.log("token invalid");
      await verifyToken(toast, navigate);
      return updatePassword(newPassword, navigate); // Another call to the function
    }

    const data = await req.json();
    return { status: req.status, data }; // Return both status and data
  } catch (error) {
    console.error(
      "Erreur lors de la requête de changement de mot de passe:",
      error,
    );
    toast.error("Erreur serveur. Veuillez réessayer plus tard.");
  }
}
