import {handleApiResponse} from "../../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function fetchActiveUsers() {
  return new Promise(async (resolve, reject) => {
    try {
  const req = await fetch(`${apiUrl}/api/getActiveUsers`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
      // If the token is invalid
      const result = await handleApiResponse(
          req,
          () => fetchActiveUsers(),
          true
      );
      resolve(result);
    } catch (error) {
      // Handle any other errors
      reject(error.message);
    }
  });
}