import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as React from "react";
import { useState } from "react";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector, GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { DialogAddIntervenant } from "../Shared/DialogAddIntervenant";

export function AddUserToolBar(props) {
  const { types, roles, newUserReload } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Ajouter un utilisateur
        </Button>
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton/>
      </GridToolbarContainer>
      <DialogAddIntervenant
        roles={roles}
        types={types}
        open={isDialogOpen}
        handleClose={handleClose}
        newUserReload={newUserReload}
      />
    </React.Fragment>
  );
}
