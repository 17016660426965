import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Autocomplete
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchActiveUsers } from "../Shared/CallAPI/Fetch/fetchActiveUsers";
import { addDomaine } from "./api_requests/addDomaine";
import { updateDomaine } from "./api_requests/updateDomaine";
import toast from "react-hot-toast";
import { fetchDomaine } from "../Shared/CallAPI/Fetch/fetchDomaine";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
        width: "30%",
        maxWidth: "none",
    },
}));

export default function DomaineGrid() {
    const [rows, setRows] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMode, setDialogMode] = useState("add"); // "add" or "update"
    const [domaineName, setDomaineName] = useState("");
    const [users, setUsers] = useState([]);
    const [responsableId, setResponsableId] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetching domains
        fetchDomaine()
            .then((data) => setRows(data))
            .catch((error) => console.error(error));

        // Fetching users
        fetchActiveUsers()
            .then((data) => {
                // We display only the users with the role "Responsable de domaine" or "Directeur"
                    const usersToDisplay = data.filter((user) => 
                        user.role === "Responsable de domaine" || user.role === "Directeur"
                    );
                    console.log(usersToDisplay)
                    setUsers(usersToDisplay);
                } 
            )
            .catch((error) => console.error(error));
    }, [navigate]);

    const handleOpenDialog = (mode, row = null) => {
        setDialogMode(mode);
        if (mode === "update" && row) {
            setSelectedRow(row);
            setDomaineName(row.nomDomaine);
            setResponsableId(row.idResp);
        } else {
            setDomaineName("");
            setResponsableId("");
        }
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setDomaineName("");
        setResponsableId("");
        setSelectedRow(null);
    };

    const handleSaveDomaine = async () => {
        if (domaineName.trim() === "" || !responsableId) {
            toast.error("Le nom du domaine et le responsable sont obligatoires.");
            return;
        }

        try {
            if (dialogMode === "add") {
                const newDomaine = await addDomaine(navigate, domaineName, responsableId);
                const newRow = {
                    idDomaine: newDomaine.id,
                    nomDomaine: newDomaine.domaine,
                    idResp: newDomaine.responsable.id,
                    NomResponsable: newDomaine.responsable.nom,
                    PrenomResponsable: newDomaine.responsable.prenom,
                };
                setRows([...rows, newRow]);
                toast.success("Nouveau domaine ajouté avec succès");
                
            } else if (dialogMode === "update") {
                
                const promise = updateDomaine(navigate, selectedRow.idDomaine, domaineName, responsableId)
                    .then((msg) => {
                        return msg;
                        });
                
                const updatedRow = {
                    ...selectedRow,
                    // Find the name and surname of the responsable with the id
                    PrenomResponsable: users.find((user) => user.id === responsableId).name,
                    NomResponsable: users.find((user) => user.id === responsableId).surname,
                    nomDomaine: domaineName,
                    idResp: responsableId,
                };
                
                const updatedRows = rows.map((row) => row.idDomaine === selectedRow.idDomaine ? updatedRow : row);
                setRows(updatedRows);
                
                await toast.promise(promise, {
                    loading: "Mise à jour du domaine...",
                    success: (msg) => msg,
                    error: (msg) => msg,
                });
            }
            handleCloseDialog();
        } catch (error) {
            console.error(error);
            toast.error(`Erreur lors de la ${dialogMode === "add" ? "création" : "mise à jour"} du domaine.`);
        }
    };

    const columns = [
        {
            field: "nomDomaine",
            headerName: "Nom du Domaine",
            width: 140,
        },
        {
            field: "idResp",
            headerName: "Responsable",
            width: 170,
            renderCell: (params) => {
                const responsable = rows.find((row) => row.idResp === params.value);
                if (responsable && responsable.PrenomResponsable && responsable.NomResponsable) {
                    return `${responsable.PrenomResponsable} ${responsable.NomResponsable}`;
                }
                return "Responsable inconnu";
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "MàJ",
            width: 70,
            getActions: ({ row }) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => handleOpenDialog("update", row)} // Open update dialog
                    color="inherit"
                />,
            ],
        },
    ];

    columns.forEach((column) => {
        column.renderHeader = (params) => <strong>{params.colDef.headerName}</strong>;
    });
    return (
        <Box sx={{ maxWidth: "400px", height: "400px" }}>
            <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button color="primary" startIcon={<AddIcon />} onClick={() => handleOpenDialog("add")}>
                    Ajouter un domaine
                </Button>
            </Box>

            {/* Unified Dialog for Add/Update */}
            <BootstrapDialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>{dialogMode === "add" ? "Ajouter un domaine" : "Modifier le domaine"}</DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nom du domaine"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={domaineName}
                        onChange={(e) => setDomaineName(e.target.value)}
                    />
                    <Autocomplete
                        value={responsableId}
                        onChange={(e, newValue) => setResponsableId(newValue)}
                        fullWidth
                        getOptionLabel={(option) => {
                            const user = users.find((user) => user.id === option);
                            return user ? `${user.surname} ${user.name}` : "Inconnu";
                        }}
                        options={users.map((user) => user.id)}
                        groupBy={(option) => users.find((user) => user.id === option)?.type}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Responsable"
                                variant="standard"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Annuler</Button>
                    <Button onClick={handleSaveDomaine}>{dialogMode === "add" ? "Ajouter" : "Mettre à jour"}</Button>
                </DialogActions>
            </BootstrapDialog>

            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.idDomaine}
                hideFooterPagination
            />
        </Box>
    );
}
