import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts";
import Box from "@mui/material/Box";

export default function DetailsDialog(props) {
  const { open, setOpen, chartData, titleDialog } = props;
  const closeDialog = () => {
    setOpen(false);
  };

  return (
      <Dialog
          open={open}
          onClose={closeDialog}
          fullWidth
          maxWidth="md"
          sx={{ textAlign: "center" }}
      >
        <DialogTitle>
          <b>{titleDialog || "Aucun filtre"}</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Détails des heures (EqTD) effectuées par domaine pour ce filtre
          </Typography>
          <Box>
            <PieChart
                series={[
                  {
                    data: chartData,
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    paddingAngle: 2,
                    outerRadius: 100,
                    innerRadius: 10,
                    cornerRadius: 2,
                  },
                ]}
                width={750}
                height={250}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
  );
}
