import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function deleteFiche(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const req = await fetch(
        `${apiUrl}/api/deleteFicheById?id=${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      // If the token is invalid
      const result = await handleApiResponse(
          req,
          () => deleteFiche(id),
          false 
      );
      window.location.reload();
      resolve(result);
    } catch (error) {
      // Handle any other errors
      reject(error.message);
    }
  });
}

