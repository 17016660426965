import "../../style/connexion.css";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {useEffect, useState} from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import logo from "../../data/logoGcuDecla-Red-Black.png";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Connexion() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem("deco") === "true") {
        localStorage.removeItem("deco");
        toast.error("Votre session a expiré. Veuillez vous reconnecter.");
        }
  }, []);

  async function ForgotPassword() {
    if (disabledButton) {
      return;
    }

    // To empty the local storage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    // desactivate the connexion and forgot password buttons
    document.querySelector("button").disabled = true;

    if (mail === "") {
      toast.error("Veuillez renseigner votre adresse mail");
      return;
    }

    try {
      setDisabledButton(true);
      const req = await fetch(`${apiUrl}/api/change-password-forgot`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mail }),
      });

      if (req.status === 200) {
        toast.success(
          "Un mot de passe temporaire a été envoyé à votre adresse email. \n Veuillez vérifier votre boîte de réception.",
          {
            duration: 5000,
          },
        );
      } else if (req.status === 500) {
        toast.error(
          "Une erreur s'est produite. \n Veuillez re-cliquer sur 'Mot de passe oublié ?",
        );
      } else if (req.status === 502) {
        toast.error(
          "Une erreur s'est produite lors de l'envoi de l'email. \n Veuillez re-cliquer sur 'Mot de passe oublié ?'",
        );
      } else {
        toast.error(
          "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
        );
      }
    } catch (error) {
      console.error("Error during password reset request:", error);
      toast.error(
        "Une erreur réseau s'est produite. Veuillez vérifier votre connexion et réessayer.",
      );
    }
    setDisabledButton(false);
  }

  async function handleClickConnexion(event) {
    event.preventDefault();

    if (mail === "") {
      toast.error("Le mail professionnel est obligatoire pour se connecter");
      return;
    }

    if (password === "") {
      toast.error("Le mot de passe est obligatoire");
      return;
    }

    setDisabledButton(true);

    const req = await fetch(`${apiUrl}/api/connexion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mail, password }),
    });

    if (req.status === 401) {
      toast.error("Adresse mail ou mot de passe incorrect");
      setDisabledButton(false);
      return;
    }

    if (req.status === 500) {
      toast.error(
        "Une erreur s'est produite. \n Veuillez réessayer de vous connecter",
      );
      setDisabledButton(false);
      return;
    }

    const data = await req.json().catch((error) => {
      toast.error(
        "Une erreur s'est produite. \n Veuillez réessayer de vous connecter",
      );
      setDisabledButton(false);
      return;
    });

    if (data.user.surname === "" || data.user.name === "") {
      toast.success(
        "Connexion réussie. \n Bienvenue ! ", // The user always has a username
      );
    } else {
      toast.success(
        "Connexion réussie. \n Bienvenue " +
          data.user.name +
          " " +
          data.user.surname +
          " ! ",
      );
    }

    // Stock user and tokens in the local storage
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
    localStorage.setItem("lastToken", new Date().toISOString());
    localStorage.setItem("deco", "false");

    // 3sec delay before redirecting to the chat page
    setTimeout(() => {
      navigate("/Home");
    }, 700);
    setDisabledButton(false);
  }

  return (
    <div className="connexion">
      <div className="logo-container">
        <img src={logo} style={{ width: "45%" }} alt="logo" />
      </div>
      <div className="form-container">
        <h1>Connexion</h1>
        <form className="form-connexion">
          <TextField
            variant="outlined"
            label="Mail INSA"
            value={mail}
            required={true}
            onChange={(e) => setMail(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Mot de passe"
            type={showPassword ? "text" : "password"}
            value={password}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={disabledButton}
            type="submit"
            variant="contained"
            onClick={handleClickConnexion}
          >
            Connexion
          </Button>
        </form>
        <p className="underlined-link" onClick={ForgotPassword}>
          Mot de passe oublié ?
        </p>
      </div>
      <Toaster />
    </div>
  );
}
