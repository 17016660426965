import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useEffect} from "react";

const filter = createFilterOptions();

export default function FreeSoloEtudiant({rows, setRows, listEtudiants, params, setListEtudiants, setWrongInput}) {
    const [open, toggleOpen] = React.useState(false);
    const [valueSelected, setValueSelected] = React.useState('');
    const handleClose = () => {
        setDialogValue('');
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState('');


    const handleSetValue = (newValue) => {
        console.log("newValue", newValue);
        if (newValue === null || newValue === undefined) {
            setWrongInput(true);
        } else {
            setWrongInput(false);
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id === params.id ? {...row, etudiant: newValue} : row
                )
            );
        }
    }
    
    const addEtudiant = () => {
        const dialogValueWithTitle = {title: dialogValue};
        setListEtudiants([...listEtudiants, dialogValueWithTitle]);
        rows.find(rows => rows.id === params.id).etudiant = dialogValue;
        setValueSelected(dialogValue);
        console.log(dialogValue);
        handleSetValue(dialogValue);
        handleClose();
    };
    
    

    // console.log(listEtudiants);
    //console.log(rows);
    
    return (
        <React.Fragment>
            <Autocomplete
                value={rows.find(row => row.id === params.id)?.etudiant || ''}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            console.log(newValue);
                            setDialogValue(newValue);
                        });
                        console.log("je ne suis un string")
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue(newValue.inputValue);
                        console.log("je suis nouveau  ...")
                    } else {
                       setValueSelected(newValue);
                       console.log("in : ", newValue)
                       handleSetValue(newValue?.title);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`});
                    }

                    return filtered;
                }}
                id="free-solo-dialog-demo"
                options={listEtudiants}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys 
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps} >
                            {/*{option.nom}*/}
                            {/*{option.inputValue ?  option.inputValue : option}*/}
                           {option.title}
                        </li>
                    );
                }}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                            },
                        }}
                        {...params}
                        style={{ textAlign: 'center' }}
                        onKeyDown={(event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        }}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Ajout d'un étudiant</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                           Voulez-vous ajouter un étudiant ?
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue}
                            onChange={(event) =>
                                setDialogValue(event.target.value)
                            }
                            label="nom"
                            type="text"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button type="submit" onClick={addEtudiant}>Ajouter</Button>
                    </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
    { nom: 'The Shawshank Redemption', prenom: 1994 },
    { nom: 'The Godfather', prenom: 1972 },
    { nom: 'The Godfather: Part II', prenom: 1974 },
    { nom: 'The Dark Knight', prenom: 2008 },
    { nom: '12 Angry Men', prenom: 1957 },
    { nom: "Schindler's List", prenom: 1993 },
    { nom: 'Pulp Fiction', prenom: 1994 },
    {
        nom: 'The Lord of the Rings: The Return of the King',
        prenom: 2003,
    },
    { nom: 'The Good, the Bad and the Ugly', prenom: 1966 },
    { nom: 'Fight Club', prenom: 1999 },
    {
        nom: 'The Lord of the Rings: The Fellowship of the Ring',
        prenom: 2001,
    },
    {
        nom: 'Star Wars: Episode V - The Empire Strikes Back',
        prenom: 1980,
    },
    { nom: 'Forrest Gump', prenom: 1994 },
    { nom: 'Inception', prenom: 2010 },
    {
        nom: 'The Lord of the Rings: The Two Towers',
        prenom: 2002,
    },
    { nom: "One Flew Over the Cuckoo's Nest", prenom: 1975 },
    { nom: 'Goodfellas', prenom: 1990 },
    { nom: 'The Matrix', prenom: 1999 },
    { nom: 'Seven Samurai', prenom: 1954 },
    {
        nom: 'Star Wars: Episode IV - A New Hope',
        prenom: 1977,
    },
    { nom: 'City of God', prenom: 2002 },
    { nom: 'Se7en', prenom: 1995 },
    { nom: 'The Silence of the Lambs', prenom: 1991 },
    { nom: "It's a Wonderful Life", prenom: 1946 },
    { nom: 'Life Is Beautiful', prenom: 1997 },
    { nom: 'The Usual Suspects', prenom: 1995 },
    { nom: 'Léon: The Professional', prenom: 1994 },
    { nom: 'Spirited Away', prenom: 2001 },
    { nom: 'Saving Private Ryan', prenom: 1998 },
    { nom: 'Once Upon a Time in the West', prenom: 1968 },
    { nom: 'American History X', prenom: 1998 },
    { nom: 'Interstellar', prenom: 2014 },
    { nom: 'Casablanca', prenom: 1942 },
    { nom: 'City Lights', prenom: 1931 },
    { nom: 'Psycho', prenom: 1960 },
    { nom: 'The Green Mile', prenom: 1999 },
    { nom: 'The Intouchables', prenom: 2011 },
    { nom: 'Modern Times', prenom: 1936 },
    { nom: 'Raiders of the Lost Ark', prenom: 1981 },
    { nom: 'Rear Window', prenom: 1954 },
    { nom: 'The Pianist', prenom: 2002 },
    { nom: 'The Departed', prenom: 2006 },
    { nom: 'Terminator 2: Judgment Day', prenom: 1991 },
    { nom: 'Back to the Future', prenom: 1985 },
    { nom: 'Whiplash', prenom: 2014 },
    { nom: 'Gladiator', prenom: 2000 },
    { nom: 'Memento', prenom: 2000 },
    { nom: 'The Prestige', prenom: 2006 },
    { nom: 'The Lion King', prenom: 1994 },
    { nom: 'Apocalypse Now', prenom: 1979 },
    { nom: 'Alien', prenom: 1979 },
    { nom: 'Sunset Boulevard', prenom: 1950 },
    {
        nom: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
        prenom: 1964,
    },
    { nom: 'The Great Dictator', prenom: 1940 },
    { nom: 'Cinema Paradiso', prenom: 1988 },
    { nom: 'The Lives of Others', prenom: 2006 },
    { nom: 'Grave of the Fireflies', prenom: 1988 },
    { nom: 'Paths of Glory', prenom: 1957 },
    { nom: 'Django Unchained', prenom: 2012 },
    { nom: 'The Shining', prenom: 1980 },
    { nom: 'WALL·E', prenom: 2008 },
    { nom: 'American Beauty', prenom: 1999 },
    { nom: 'The Dark Knight Rises', prenom: 2012 },
    { nom: 'Princess Mononoke', prenom: 1997 },
    { nom: 'Aliens', prenom: 1986 },
    { nom: 'Oldboy', prenom: 2003 },
    { nom: 'Once Upon a Time in America', prenom: 1984 },
    { nom: 'Witness for the Prosecution', prenom: 1957 },
    { nom: 'Das Boot', prenom: 1981 },
    { nom: 'Citizen Kane', prenom: 1941 },
    { nom: 'North by Northwest', prenom: 1959 },
    { nom: 'Vertigo', prenom: 1958 },
    {
        nom: 'Star Wars: Episode VI - Return of the Jedi',
        prenom: 1983,
    },
    { nom: 'Reservoir Dogs', prenom: 1992 },
    { nom: 'Braveheart', prenom: 1995 },
    { nom: 'M', prenom: 1931 },
    { nom: 'Requiem for a Dream', prenom: 2000 },
    { nom: 'Amélie', prenom: 2001 },
    { nom: 'A Clockwork Orange', prenom: 1971 },
    { nom: 'Like Stars on Earth', prenom: 2007 },
    { nom: 'Taxi Driver', prenom: 1976 },
    { nom: 'Lawrence of Arabia', prenom: 1962 },
    { nom: 'Double Indemnity', prenom: 1944 },
    {
        nom: 'Eternal Sunshine of the Spotless Mind',
        prenom: 2004,
    },
    { nom: 'Amadeus', prenom: 1984 },
    { nom: 'To Kill a Mockingbird', prenom: 1962 },
    { nom: 'Toy Story 3', prenom: 2010 },
    { nom: 'Logan', prenom: 2017 },
    { nom: 'Full Metal Jacket', prenom: 1987 },
    { nom: 'Dangal', prenom: 2016 },
    { nom: 'The Sting', prenom: 1973 },
    { nom: '2001: A Space Odyssey', prenom: 1968 },
    { nom: "Singin' in the Rain", prenom: 1952 },
    { nom: 'Toy Story', prenom: 1995 },
    { nom: 'Bicycle Thieves', prenom: 1948 },
    { nom: 'The Kid', prenom: 1921 },
    { nom: 'Inglourious Basterds', prenom: 2009 },
    { nom: 'Snatch', prenom: 2000 },
    { nom: '3 Idiots', prenom: 2009 },
    { nom: 'Monty Python and the Holy Grail', prenom: 1975 },
];