import React, { useEffect, useState } from "react";
import {
    Typography,
    TextField,
    Grid,
    Button,
    Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AddNewFicheTutore } from "../api_requests/addNewFicheTutore";
import Box from "@mui/material/Box";

export default function FicheProjetTutore({
    handlePopUp,
    allUsers,
    domaineData,
    promotionData,
    parcoursData,
    semestreData,
    anneeData,
                                             }) {
    const [codeEC, setCodeEC] = useState(null);
    const [libelle, setLibelle] = useState(null);
    const [domaine, setDomaine] = useState(null);
    const [promotion, setPromotion] = useState(null);
    const [parcours, setParcours] = useState(null);
    const [semestre, setSemestre] = useState(null);
    const [cm, setCm] = useState(2);
    const [tp, setTp] = useState(1);
    const [budget, setBudget] = useState(null);
    const [budget_par_etudiant, setBudgetParEtudiant] = useState(null);
    const [ects, setEcts] = useState(null);
    const [responsable, setResponsable] = useState(null);
    const [coefB, setCoefB] = useState(1);
    const [annee, setAnnee] = useState(null);

    const [errors, setErrors] = useState({
        cm: false,
        tp: false,
    });
    
    const navigate = useNavigate();



    useEffect(() => {

        
        // Set the current year as the default value
        const currentYear = anneeData.find((year) => year.currentAnneeScolaire);
        if (currentYear) {
            setAnnee(currentYear);
        }
    }, []);

    const handleSaveFiche = () => {
        if (!domaine || !promotion || !parcours || !semestre || !responsable) {
            console.error("One or more required fields are null or undefined");
            toast.error("Un ou plusieurs champs obligatoires sont nuls ou indéfinis");
            return;
        }

        console.log(responsable);
        const ficheData = {
            codeEC,
            libelle,
            Domaine: { id: domaine.idDomaine },
            Promotion: { id: promotion.id },
            Parcours: { id: parcours.id },
            Semestre: { id: semestre.id },
            td: 0,
            budget: parseFloat(budget),
            budgetEtudiant: parseFloat(budget_par_etudiant),
            ects: parseFloat(ects),
            Responsable: {
                id: responsable.id,
            },
            coefB: 1,
            type_de_fiche : "Projet Tutore",
            annee : annee.anneeScolaire
        };
        //console.log(JSON.stringify(ficheData));
        const reqStatus = AddNewFicheTutore(ficheData);
    };

    const handleNumericChange = (setter, errorKey) => (event) => {
        const value = event.target.value;
        setter(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [errorKey]: isNaN(value),
        }));
    };

    return (
        <Box sx={{ marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>
            <Toaster />

            <Grid container spacing={2} sx={{ marginTop: "0.1%" }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        options={anneeData}
                        getOptionLabel={(option) => option.anneeScolaire}
                        renderInput={(params) => <TextField {...params} label="Année" />}
                        value={annee}
                        onChange={(event, newValue) => setAnnee(newValue)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        label="Code EC"
                        fullWidth
                        value={codeEC}
                        onChange={(e) => setCodeEC(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        options={promotionData}
                        getOptionLabel={(option) => option.nomPromotion}
                        renderInput={(params) => (
                            <TextField {...params} label="Promotion" />
                        )}
                        value={promotion}
                        onChange={(event, newValue) => setPromotion(newValue)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        options={domaineData}
                        getOptionLabel={(option) => option.nomDomaine}
                        renderInput={(params) => <TextField {...params} label="Domaine" />}
                        value={domaine}
                        onChange={(event, newValue) => setDomaine(newValue)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Libellé"
                        fullWidth
                        value={libelle}
                        onChange={(e) => setLibelle(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Autocomplete
                        options={semestreData}
                        getOptionLabel={(option) => option.nomSemestre}
                        renderInput={(params) => <TextField {...params} label="Semestre" />}
                        value={semestre}
                        onChange={(event, newValue) => setSemestre(newValue)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        options={parcoursData}
                        getOptionLabel={(option) => option.nomParcour}
                        renderInput={(params) => <TextField {...params} label="Parcours" />}
                        value={parcours}
                        onChange={(event, newValue) => setParcours(newValue)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Budget total"
                        fullWidth
                        value={budget}
                        onChange={handleNumericChange(setBudget, "budget")}
                        error={errors.budget}
                        helperText={
                            errors.budget ? "Veuillez entrer une valeur numérique" : ""
                        }
                    />
                </Grid> 
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Budget par etudiant"
                        fullWidth
                        value={budget_par_etudiant}
                        onChange={handleNumericChange(setBudgetParEtudiant, "budget par étudiant")}
                        error={errors.budgetEtudiant}
                        helperText={
                            errors.budgetEtudiant ? "Veuillez entrer une valeur numérique" : ""
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="ECTS"
                        fullWidth
                        value={ects}
                        onChange={handleNumericChange(setEcts, "ects")}
                        error={errors.ects}
                        helperText={
                            errors.ects ? "Veuillez entrer une valeur numérique" : ""
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={allUsers.filter(user => (user.role === "Responsable EC" || user.role === "Responsable de domaine" || user.role === "Directeur"))}
                        getOptionLabel={(option) => `${option.name} ${option.surname}`}
                        renderInput={(params) => (
                            <TextField {...params} label="Responsable" />
                        )}
                        value={responsable}
                        onChange={(event, newValue) => setResponsable(newValue)}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    onClick={handleSaveFiche}
                    color="primary"
                    variant="contained"
                    sx={{ marginRight: "1%" }}
                    disabled = { !promotion || !parcours || !semestre || errors.budget || errors.ects ||  errors.budgetEtudiant  ||!domaine || !responsable || !budget || !budget_par_etudiant || !ects }
                >
                    Sauvegarder
                </Button>
                <Button onClick={handlePopUp} color="primary">
                    Annuler
                </Button>
            </Box>
        </Box>
    );
}
