import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function addInterventionTutore(intervention, navigate) {
  console.log(JSON.stringify(intervention));
  return new Promise(async (resolve, reject) => {
    try {
      const req = await fetch(`${apiUrl}/api/addInterventionProjetTutore`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(intervention),
      });
      // If the token is invalid
      const result = await handleApiResponse(
          req,
          () => addInterventionTutore(intervention, navigate),
          true
      );
      resolve({ message: "L'intervention a été ajouté avec succès !", data: result.intervention });
    } catch (error) {
      // Handle any other errors
      reject(error.message);
    }
  });
}