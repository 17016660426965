import { verifyToken } from "../../../../utils/utils";
import toast from "react-hot-toast";
import { handleApiResponse } from "../../../../utils/errorHandler";

const apiUrl = process.env.REACT_APP_API_URL;

export async function import_students(studentsData,year) {
    return new Promise(async (resolve, reject) => {
        try {
            // On prépare les données du formulaire pour l'envoi du fichier
            const req = await fetch(`${apiUrl}/api/import-students`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({ student: studentsData, year : year }), // On envoie le fichier via le FormData
            });

            // Si la réponse n'est pas OK, on gère les erreurs (s'il y a des erreurs)
            if (!req.ok) {
                const result = await handleApiResponse(req, import_students, true);
                console.log("Error in response:", result);
                reject(result);
            }

            // Lecture de la réponse JSON pour voir si l'importation s'est bien déroulée
            const result = await req.json();
            resolve(result); // On retourne le résultat (success ou erreur)

        } catch (error) {
            // Gestion des erreurs
            reject(error.message);
        }
    });
}
