import React from 'react';
import {export_intervention} from "./CallAPI/Fetch/export_intervention";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import toast, {Toaster} from "react-hot-toast";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";



const ExportButton = ({year}) => {
    const handleExport = () => {
        console.log('years', year)
        const promise =  new Promise(async (resolve, reject) => {
            export_intervention(year)
                .then((res) => {
                    const blob = res.data;
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'exported_data.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    resolve(res.message)
                })
                .catch((error) => {
                    console.error('Erreur:', error);
                    reject(error)
                })
        })  

        toast.promise(promise, {
            loading: "Export des données en cours...",
            success: (msg) => <Typography>{msg}</Typography>,
            error: (msg) => <Typography>{msg}</Typography>,
        });
    };

    return (
        <Box>
            <Toaster/>
            <Button onClick={handleExport}
                    startIcon={ <FileDownloadIcon/>}>
                Exporter en CSV
            </Button>
        </Box>);
        
};

export default ExportButton;
