import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Rating} from "@mui/lab";
import {useGridApiContext} from "@mui/x-data-grid";
import {Autocomplete, TextField} from "@mui/material";

export  function renderIntervenant(params) {
    return <Typography>{params.row.nom}</Typography>
}


export function RenderIntervenantFreeSolo(props){
    const { id, row, value, field, hasFocus, allUsers, setWrongInput } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef(null);

    const handleChange = (event, newValue) => {
        if(newValue === null){
            setWrongInput(true);
            return;
        }
        console.log(newValue)
        apiRef.current.setEditCellValue({ id, field, value: newValue.id, });
    };



    return (
        <Autocomplete
        sx = {{ width: 400 }}
        value={allUsers.find(user => user.id === value) || value}
        onChange={handleChange}
        options={allUsers.sort((a, b) => a.type.localeCompare(b.type))}
        groupBy={(option) => option.type}

        getOptionLabel={(option) => {
            if (typeof option === 'object') {
                return option.surname + ' ' + option.name;
            }
            else
                return option;
        }}

        isOptionEqualToValue={(option, value) => {
               
                if(typeof value === 'object') {
                    return option.id === value.id;
                }
                return option.surname + ' ' + option.name === value;
            
        }}

        renderInput={(params) => (

            <TextField {...params} label=""
                       sx={{
                           "& .MuiOutlinedInput-root": {
                               "& fieldset": {
                                   border: "none",
                               },
                           },
                       }}/>
        )}
        
        
        
        
        />
    );
}

