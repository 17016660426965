import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";

export async function updateParcours(navigate, id, newName) {
    return new Promise(async (resolve, reject) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/api/updateParcours`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({ id, newName }),
            });

            if (response.status === 401) {
                console.log("Token invalide");
                await verifyToken(toast, navigate).then(() => {
                    updateParcours(navigate, newName).then(resolve).catch(reject);
                });
                return;
            }

            if (response.status === 403) {
                reject("Vous n'êtes pas autorisé à mettre à jour le parcours.");
                return;
            }

            if (response.status === 400) {
                const error = await response.json();
                reject(error.error || "Le nom du parcours est requis.");
                return;
            }

            if (response.status === 500) {
                reject(
                    "Erreur interne du serveur lors de la mise à jour du parcours.",
                );
                return;
            }

            if (response.ok) {
                resolve("La promotion " + newName + " a été mise à jour avec succès.");
            } else {
                reject(
                    "Une erreur s'est produite lors de la mise à jour de la promotion.",
                );
            }
        } catch (error) {
            reject(error.message || "Une erreur s'est produite.");
        }
    });
}
