import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Grid,
  Button,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AddNewFicheEC } from "../api_requests/addNewFicheEC";
import Box from "@mui/material/Box";

export default function FicheSimple({
  handlePopUp,
  allUsers,
  domaineData,
  promotionData,
  parcoursData,
  semestreData,
  anneeData,
}) {
  const [codeEC, setCodeEC] = useState(null);
  const [libelle, setLibelle] = useState(null);
  const [domaine, setDomaine] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [parcours, setParcours] = useState(null);
  const [semestre, setSemestre] = useState(null);
  const [cm, setCm] = useState(0);
  const [td, setTd] = useState(0);
  const [tp, setTp] = useState(0);
  const [budget, setBudget] = useState(null);
  const [ects, setEcts] = useState(null);
  const [responsable, setResponsable] = useState(null);
  const [coefA, setCoefA] = useState(1);
  const [coefB, setCoefB] = useState(1);
  const [coefC, setCoefC] = useState(1);
  const [annee, setAnnee] = useState(null);

  const [errors, setErrors] = useState({
    cm: false,
    td: false,
    tp: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Set the current year as the default value
    const currentYear = anneeData.find((year) => year.currentAnneeScolaire);
    if (currentYear) {
      setAnnee(currentYear);
    }
  }, []);

  const handleSaveFiche = () => {
    if (!domaine || !promotion || !parcours || !semestre || !responsable) {
      toast.error("Un ou plusieurs champs obligatoires sont nuls ou indéfinis");
      return;
    }

    const ficheData = {
      codeEC,
      libelle,
      Domaine: { id: domaine.idDomaine },
      Promotion: { id: promotion.id },
      Parcours: { id: parcours.id },
      Semestre: { id: semestre.id },
      cm: parseFloat(cm),
      td: parseFloat(td),
      tp: parseFloat(tp),
      budget: parseFloat(budget),
      ects: parseFloat(ects),
      Responsable: {
        id: responsable.id,
      },
      coefA: parseFloat(coefA),
      coefB: parseFloat(coefB),
      coefC: parseFloat(coefC),
      type_de_fiche : "Fiche EC",
      annee : annee.anneeScolaire
    };
    const reqStatus = AddNewFicheEC(ficheData);
  };

  const handleNumericChange = (setter, errorKey) => (event) => {
    const value = event.target.value;
    setter(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorKey]: isNaN(value),
    }));
  };

  return (
    <Box sx={{ marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>
      <Toaster />

      <Grid container spacing={2} sx={{ marginTop: "0.1%" }}>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={anneeData}
            getOptionLabel={(option) => option.anneeScolaire}
            renderInput={(params) => <TextField {...params} label="Année" />}
            value={annee}
            onChange={(event, newValue) => setAnnee(newValue)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Code EC"
            fullWidth
            value={codeEC}
            onChange={(e) => setCodeEC(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={promotionData}
            getOptionLabel={(option) => option.nomPromotion}
            renderInput={(params) => (
              <TextField {...params} label="Promotion" />
            )}
            value={promotion}
            onChange={(event, newValue) => setPromotion(newValue)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={domaineData}
            getOptionLabel={(option) => option.nomDomaine}
            renderInput={(params) => <TextField {...params} label="Domaine" />}
            value={domaine}
            onChange={(event, newValue) => setDomaine(newValue)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Libellé"
            fullWidth
            value={libelle}
            onChange={(e) => setLibelle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            options={semestreData}
            getOptionLabel={(option) => option.nomSemestre}
            renderInput={(params) => <TextField {...params} label="Semestre" />}
            value={semestre}
            onChange={(event, newValue) => setSemestre(newValue)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options={parcoursData}
            getOptionLabel={(option) => option.nomParcour}
            renderInput={(params) => <TextField {...params} label="Parcours" />}
            value={parcours}
            onChange={(event, newValue) => setParcours(newValue)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Budget"
            fullWidth
            value={budget}
            onChange={handleNumericChange(setBudget, "budget")}
            error={errors.budget}
            helperText={
              errors.budget ? "Veuillez entrer une valeur numérique" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="ECTS"
            fullWidth
            value={ects}
            onChange={handleNumericChange(setEcts, "ects")}
            error={errors.ects}
            helperText={
              errors.ects ? "Veuillez entrer une valeur numérique" : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Heures de face à face</Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            label="CM"
            fullWidth
            value={cm}
            onChange={handleNumericChange(setCm, "cm")}
            error={errors.cm}
            helperText={errors.cm ? "Veuillez entrer une valeur numérique" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            label="TD"
            fullWidth
            value={td}
            onChange={handleNumericChange(setTd, "td")}
            error={errors.td}
            helperText={errors.td ? "Veuillez entrer une valeur numérique" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            label="TP"
            fullWidth
            value={tp}
            onChange={handleNumericChange(setTp, "tp")}
            error={errors.tp}
            helperText={errors.tp ? "Veuillez entrer une valeur numérique" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={allUsers.filter(user => (user.role === "Responsable EC" || user.role === "Responsable de domaine" || user.role === "Directeur"))}
            getOptionLabel={(option) => `${option.name} ${option.surname}`}
            renderInput={(params) => (
              <TextField {...params} label="Responsable" />
            )}
            value={responsable}
            onChange={(event, newValue) => setResponsable(newValue)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Pondération :</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="a"
                fullWidth
                value={coefA}
                onChange={handleNumericChange(setCoefA, "coefA")}
                error={errors.coefA}
                helperText={
                  errors.coefA ? "Veuillez entrer une valeur numérique" : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="b"
                fullWidth
                value={coefB}
                onChange={handleNumericChange(setCoefB, "coefB")}
                error={errors.coefB}
                helperText={
                  errors.coefB ? "Veuillez entrer une valeur numérique" : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="c"
                fullWidth
                value={coefC}
                onChange={handleNumericChange(setCoefC, "coefC")}
                error={errors.coefC}
                helperText={
                  errors.coefC ? "Veuillez entrer une valeur numérique" : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleSaveFiche}
          color="primary"
          variant="contained"
          sx={{ marginRight: "1%" }}
          disabled = {errors.cm || errors.td || errors.tp || errors.budget || errors.ects || errors.coefA ||  errors.coefB ||  errors.coefC ||  !domaine || !promotion || !parcours || !semestre || !responsable || !budget || !ects}
        >
          Sauvegarder
        </Button>
        <Button onClick={handlePopUp} color="primary">
          Annuler
        </Button>
      </Box>
    </Box>
  );
}
