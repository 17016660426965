import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridActionsCellItem,
    GridRowModes,
    GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchParcours } from "../Shared/CallAPI/Fetch/fetchParcours";
import { addParcours } from "./api_requests/addParcours";
import toast from "react-hot-toast";
import { updateParcours } from "./api_requests/updateParcours";
import Typography from "@mui/material/Typography";

export default function ParcoursGrid() {
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newParcoursName, setNewParcoursName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetchParcours()
            .then((data) => setRows(data))
            .catch((error) => console.error(error));
    }, [navigate]);

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setNewParcoursName("");
    };

    const handleAddParcours = async () => {
        if (newParcoursName.trim() === "") {
            toast.error("Le nom du parcours est obligatoire");
            return;
        }

        try {
            const newParcours = await addParcours(navigate, newParcoursName);
            const newRow = { id: newParcours.id, nomParcour: newParcours.parcours };
            setRows([...rows, newRow]);
            handleCloseDialog();
            toast.success("Nouveau parcours ajouté avec succès");
        } catch (error) {
            console.error(error);
            toast.error("Erreur lors de l'ajout du parcours");
        }
    };

    const processRowUpdate = async (newRow) => {
        console.log("je suis là");
        if (newRow.nomParcour.trim() === "") {
            throw new Error("Le nom du parcours est obligatoire");
        }

        try {
            const promise = updateParcours(navigate, newRow.id, newRow.nomParcour)
                .then( (msg) => {
                    return msg;
                });
            
            const updatedRows = rows.map((row) => (row.id === newRow.id ? newRow : row));
            setRows(updatedRows);
            toast.promise(promise, {
                loading: "Mise à jour du parcours en cours...",
                success: (msg) => <Typography>{msg}</Typography>,
                error: (msg) => <Typography>{msg}</Typography>,
            });
            return newRow;
        } catch (error) {
            toast.error("Erreur lors de la mise à jour du parcours");
            console.error(error);
            return null;
        }
    };

    const handleSaveClick = (id) => async () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View },
        }));
    };

    const handleCancelClick = (id) => () => {
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        } else {
            setRowModesModel((prevModel) => ({
                ...prevModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            }));
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    // When an error occurs during the update of a row
    const handleProcessRowUpdateError = (error) => {
        toast.error(error.message);
    };

    const columns = [
        {
            field: "nomParcour",
            headerName: "Parcours",
            width: 180,
            editable: true,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "MàJ",
            width: 70,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{ color: "primary.main" }}
                            onClick={(event) => {
                                event.stopPropagation(); // Stop the row from losing focus
                                handleSaveClick(id)();
                            }}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    columns.forEach((column) => {
        column.renderHeader = (params) => <strong>{params.colDef.headerName}</strong>;
    });

    return (
        <Box sx={{ maxWidth: "320px", height: "400px" }}>
            <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleOpenDialog}>
                    Ajouter un parcours
                </Button>
            </Box>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Ajouter un parcours</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nom du parcours"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newParcoursName}
                        onChange={(e) => setNewParcoursName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Annuler</Button>
                    <Button onClick={handleAddParcours}>Ajouter</Button>
                </DialogActions>
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={setRowModesModel}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                hideFooterPagination
            />
        </Box>
    );
}
