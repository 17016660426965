import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import "../../style/Comptes.css";

export default function TotalsBox(props) {
  const {
    totals,
    globalTotal,
    valueType,
    showGlobalTotals,
    handleToggleTotals,
  } = props;

  // Function to convert ratio to percentage
  const convertToPercentage = (value) => (value * 100).toFixed(2) + " %";
  const tooltipGlobalTotal = "Total des toutes les interventions présente dans la base de données";
  const tooltipVisibleTotal = "Total des heures visibles actuellement dans le tableau (limité à 100 lignes)";


  return (
    <Box className="totals-box">
      <Box className="totals-box-container">
        {Object.keys(totals).map((domain) => {
          const visibleDomaine = JSON.parse(localStorage.getItem("visibleDomaines"));
          if (!visibleDomaine[domain]) return null;
          return (
          <Box key={domain} className="totals-box-item">
            {valueType === 1 ? (
              <Tooltip
                title={convertToPercentage(totals[domain])}
                placement="top"
              >
                <span>
                  <strong>{domain}</strong> <br />
                  {totals[domain]}
                </span>
              </Tooltip>
            ) : (
              <>
                <strong>{domain}</strong> <br />
                <span>{totals[domain]} h</span>
              </>
            )}
          </Box>
          );
        })}
        <Divider
          className="totals-box-divider"
          orientation="vertical"
          flexItem
        />
        <Box className="totals-box-item">
          {valueType === 1 ? (
            <Tooltip title={convertToPercentage(globalTotal)}>
              <span>
                <strong>Total</strong> <br />
                {globalTotal}
              </span>
            </Tooltip>
          ) : (
            <>
              <strong>Total</strong> <br />
              <span>{Number.isInteger(globalTotal) ? globalTotal : globalTotal.toFixed(2)} h</span>
            </>
          )}
        </Box>
        <Divider
          className="totals-box-divider"
          orientation="vertical"
          flexItem
        />
        <Tooltip title={showGlobalTotals ? tooltipGlobalTotal : tooltipVisibleTotal}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={showGlobalTotals} />}
            label={<span style={{ fontSize: "small" }}>Total Global</span>}
            onChange={handleToggleTotals}
          />
        </FormGroup>
        </Tooltip>
      </Box>
    </Box>
  );
}
