import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
const apiUrl = process.env.REACT_APP_API_URL;
export async function addDomaine(navigate, domaine, responsable ) {
  console.log({domaine, responsable})
  const response = await fetch(`${apiUrl}/api/addDomaine`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      nameOfDomaine: domaine,
      responsable: { id: responsable }
    }),
  });

  if (response.status === 401) {
    console.log("token invalid");
    await verifyToken(toast, navigate).then(() => {
      addDomaine(navigate, domaine);
    });
  }

  const contentType = response.headers.get("content-type");
  if (!contentType || !contentType.includes("application/json")) {
    throw new Error("Unexpected response format");
  }

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Error while saving new domain");
  }

  return await response.json();
}
