import { verifyToken } from "../../../../utils/utils";
import toast from "react-hot-toast";
const apiUrl = process.env.REACT_APP_API_URL;
export async function fetchEtudiant(year) {
    const req = await fetch(`${apiUrl}/api/getStudentsByYear?year=${year}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    // If the token is invalid
    if (req.status === 401) {
        console.log("token invalid");
        const tokenValid = await verifyToken(toast);
        if (tokenValid) {
            return fetchEtudiant(year);
        }
        else (
            toast.error("Vous avez été déconnecté")
        )
    }

    if (req.status === 200) {
        const etudiants = await req.json();
        return etudiants;
    } else {
        toast.error("Erreur lors de la récupération de la liste des étudiants");
    }

    return req;
}
