import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector, GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import * as React from "react";
import PercentIcon from "@mui/icons-material/Percent";
import NumbersIcon from "@mui/icons-material/Numbers";
import { Autocomplete, TextField } from "@mui/material";

export function ButtonChangeUnit(props) {
  const {
    valueType,
    setValueType,
    schoolYears,
    selectedSchoolYear,
    setSelectedSchoolYear,
  } = props;

  const handleClick = () => {
    setValueType(valueType === 0 ? 1 : 0);
    localStorage.setItem("valueType", valueType);
  };

  return (
    <GridToolbarContainer className="toolbar">
      <div>
        <Button
          color="primary"
          startIcon={valueType === 0 ? <PercentIcon /> : <NumbersIcon />}
          onClick={handleClick}
        >
          Changer l'unité
        </Button>
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton/>
        <GridToolbarColumnsButton />
      </div>
      <Autocomplete
        size="small"
        disableClearable
        className="select-school-year-toolbar-fiche"
        options={schoolYears}
        getOptionLabel={(option) => option.anneeScolaire ?? ""}
        defaultValue={selectedSchoolYear}
        renderInput={(params) => (
          <TextField {...params} label="Année scolaire" />
        )}
        value={selectedSchoolYear}
        onChange={(event, value) => setSelectedSchoolYear(value)}
      />
    </GridToolbarContainer>
  );
}
