import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";

export async function updateDateMax(navigate, dateMax) {
  return new Promise(async (resolve, reject) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${apiUrl}/api/updateDateMax`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ dateMax }),
      });

      if (response.status === 401) {
        console.log("Token invalide");
        await verifyToken(toast, navigate).then(() => {
          updateDateMax(navigate, dateMax).then(resolve).catch(reject);
        });
        return;
      }

      if (response.status === 403) {
        reject("Vous n'êtes pas autorisé à mettre à jour la date.");
        return;
      }

      if (response.status === 400) {
        const error = await response.json();
        reject(error.error || "La date est requise.");
        return;
      }

      if (response.status === 500) {
        reject("Erreur interne du serveur lors de la mise à jour de la date.");
        return;
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        reject("Format de réponse inattendu.");
        return;
      }

      if (response.ok) {
        resolve("Date maximum de dépot mise à jour avec succès.");
      } else {
        reject("Une erreur s'est produite lors de la mise à jour de la date.");
      }
    } catch (error) {
      reject(error.message || "Une erreur s'est produite.");
    }
  });
}
