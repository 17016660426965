import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DrawerBar from "../Shared/DrawerBar";
import FiltresPopUp from "./FiltresPopUp";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { fetchFiltersIntervention } from "./api_requests/fetchFiltersIntervention";
import { fetchDetails } from "./api_requests/fetchDetails";
import DetailsDialog from "./DetailsRowDialog";
import "../../style/Dashboard.css";
import Typography from "@mui/material/Typography";
import GridBoardIntervention from "./GridBoardIntervention";
import {fetchFiltersFiche} from "./api_requests/fetchFiltersFiche";
import GridBoardFiche from "./GridBoardFiche";
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [dataIntervention, setDataIntervention] = useState([]);
  const [dataFiche, setDataFiche] = useState([]);
  const navigate = useNavigate();
  const [openDetail, setOpenDetail] = useState(false);
  const [rowData, setRowData] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [titleDialog, setTitleDialog] = useState("");
  const [filtersIntervention, setFiltersIntervention] = useState(true);

  // Vider le localStorage pour les filtres
  //   useEffect(() => {
  //       localStorage.removeItem("filtersDashboard");
  //   }, []);
  
  const handleClickDetails = (rowData) => {
    setRowData(rowData);
    setTrigger(trigger + 1); // Force the useEffect to rerun
  };

  // Fetch the detailed data for the selected row and open the dialog
  useEffect(() => {
    if (Object.keys(rowData).length !== 0) {
      const filters = {
        promotion: rowData.promotion || "",
        parcours: rowData.parcours || "",
        semestre: rowData.semestre || "",
        annee: rowData.annee || "",
      };

      // Generate the title based on the filters
      const filtersArray = [
        filters.promotion,
        filters.parcours,
        filters.semestre,
        filters.annee,
      ].filter(Boolean); // Remove empty strings
      
      const title = filtersArray.length > 0 ? filtersArray.join(" / ") : "Aucun filtre";
      setTitleDialog(title);

      // Fetch the details for the selected row
      fetchDetails(navigate, filters)
          .then((data) => {
            // Transform the data into a format suitable for the chart
            const transformedData = Object.values(data).map((item, index) => ({
              id: index,
              value: item.totalEqTD,
              label: item.domaine,
            }));
            
            if(transformedData.length === 0) {
                toast.error("Aucune donnée pour ce filtre.");
                return;
            }

            setChartData(transformedData);
            setOpenDetail(true);
          })
          .catch((error) => {
            console.error("Error fetching details:", error);
            toast.error("Erreur lors de la récupération des détails.");
          });
    }
  }, [rowData, trigger, navigate]);

  const handleFiltres = () => {
    setOpen(!open);
  };

  // Fetch datas for both gridboards when the component loads or filters change
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    if (role.id !== 1) {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
      return;
    }

    if (!open) {
      const filtersDashboard = localStorage.getItem("filtersDashboard");
      if (filtersDashboard) {
          const filtersArray = JSON.parse(filtersDashboard);

          let newDataInterventions = [];
          let newDataFiches = [];
          
          // Fetch data for each filter for interventions and fiches
          const promisesInterventionsDashboard = filtersArray.map((filter) => {
              return fetchFiltersIntervention(navigate, filter)
                  .then((res) => {
                      const newRowIntervention = {...res, id: `${filter.id}`};
                      newDataInterventions[filter.id] = newRowIntervention;
                  })
                  .catch((err) => {
                      console.error("Error fetching data interventions:", err);
                  });
          });

          const promisesFichesDashboard = filtersArray.map((filter) => {
              return fetchFiltersFiche(navigate, filter)
                  .then((res) => {
                      const newRowFiche = {...res, id: `${filter.id}`};
                      newDataFiches[filter.id] = newRowFiche;
                  })
                  .catch((err) => {
                      console.error("Error fetching data fiches:", err);
                  });
          });
          
        Promise.all(promisesInterventionsDashboard).then(() => {
            setDataIntervention(newDataInterventions);

            Promise.all(promisesFichesDashboard).then(() => {
                // Calcul of the ratio between interventions and fiches
                newDataFiches.forEach(fiche => {
                    // find the filter row corresponding to the fiche in the interventions data
                    const intervention = newDataInterventions.find(intervention => intervention.id === fiche.id);

                    if (intervention) {
                        // percent calculation
                        fiche.ratioCM = intervention.CM_Inter && fiche.CM_Fiche ? ((intervention.CM_Inter / fiche.CM_Fiche) * 100).toFixed(2) : '';
                        fiche.ratioTD = intervention.TD_Inter && fiche.TD_Fiche ? ((intervention.TD_Inter / fiche.TD_Fiche) * 100).toFixed(2) : '';
                        fiche.ratioTP = intervention.TP_Inter && fiche.TP_Fiche ? ((intervention.TP_Inter / fiche.TP_Fiche) * 100).toFixed(2) : '';
                        fiche.ratioTotal = intervention.TotalEqTD && fiche.Budget ? ((intervention.TotalEqTD / fiche.Budget) * 100).toFixed(2) : '';
                    }
                });
                setDataFiche(newDataFiches);
            });
        });
        
      }
    }
  }, [open, navigate]);

  return (
      <Box className="dashboard">
        <FiltresPopUp open={open} setOpen={setOpen} sx={{ width: "100%" }} />
        <DrawerBar selected={"/dashboard"} />
        <DetailsDialog
            open={openDetail}
            setOpen={setOpenDetail}
            chartData={chartData}
            titleDialog={titleDialog}
        />
        <Box className="grid-button-title-container">
          <Box className="button-title-container">
            <Tooltip
                title="Ajouter, modifier ou supprimer des filtres"
                placement="right"
            >
              <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ marginRight: "50px" }}
                  onClick={handleFiltres}
              >
                Filtres
              </Button>
            </Tooltip>
              <Tooltip title={filtersIntervention ? "Passer au tableau de bord fiches" : "Passer au tableau de bord interventions"}>
                  <ChangeCircleRoundedIcon
                      onClick={() => setFiltersIntervention(!filtersIntervention)}
                      size='large'
                      sx={{ cursor: 'pointer', marginRight: "50px"}}
                  />
              </Tooltip>
             <Typography variant="h6" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              Tableau de bord ({filtersIntervention ? "Heures déclarées" : "Heures affectées"})
            </Typography>
          </Box>
            {filtersIntervention ? (
                <GridBoardIntervention data={dataIntervention} handleClickDetails={handleClickDetails} />
            ) : (
                <GridBoardFiche data={dataFiche}/>
            )}
        </Box>
      </Box>
  );
}
