import React, { useState } from 'react';
import Papa from 'papaparse';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import toast, { Toaster } from 'react-hot-toast';
import {import_students} from "./CallAPI/Fetch/importStudent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const ImportStudentButton = ({year}) => {
    const [file, setFile] = useState(null);
    const [buttonStyle, setButtonStyle] = useState("default");

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setButtonStyle("selected"); // Change le style du bouton si un fichier est sélectionné
        } else {
            setFile(null);
            setButtonStyle("default"); // Remet le style par défaut
        }
    };

    // Fonction pour envoyer les données au serveur
    const handleImport = () => {
        if (!file) {
            toast.error('Veuillez sélectionner un fichier CSV');
            return;
        }

        Papa.parse(file, {
            header: true, // Pour lire les en-têtes du fichier CSV
            delimiter: ";", // Spécifie le délimiteur utilisé dans le fichier CSV
            complete: function(results) {
                // results.data contient les lignes du CSV sous forme de tableau d'objets
                const studentsData = results.data;
                const promise = import_students(studentsData,year).then((msg)=> {
                    setFile(null);
                    setButtonStyle("default"); // Remet le style par défaut
                    return msg
                }).catch((error) => {
                    window.location.reload();
                    return error;
                });

                toast.promise(promise, {
                    loading: 'Importation en cours...',
                    success: 'Étudiants importés avec succès',
                    error: 'Erreur lors de l\'importation',
                });
            },
            error: function(error) {
                console.error('Erreur de parsing CSV:', error);
                toast.error('Erreur lors de la lecture du fichier CSV');
            }
        });
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%" }}>
        <Toaster />
        <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="csv-upload"
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", width: "100%" }}>
            <label htmlFor="csv-upload">
                <Button variant="contained" component="span" sx={{ width: "100%" }}
                    color={buttonStyle === "default" ? "primary" : "success"}>
                    {file ? "Changer de fichier" : "Sélectionner un fichier CSV (colonne Nom, Prénom, Promotion)"}
                </Button>
            </label>

            {file && (
                <>
                    <Typography variant="body2">
                        Fichier sélectionné : <b>{file.name}</b>
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleImport}
                        sx={{ marginTop: '10px', width: '50%' }}
                    >
                        Importer le fichier
                    </Button>
                </>
            )}
        </Box>
      </Box>
    );
};

export default ImportStudentButton;
