import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";

export async function addPromotion(navigate, promotion) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const response = await fetch(`${apiUrl}/api/addPromotion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: '{"name" : ' + JSON.stringify(promotion) + "}",
  });
  console.log('{"name" : ' + JSON.stringify(promotion) + "}");

  if (response.status === 401) {
    console.log("token invalid");
    await verifyToken(toast, navigate).then(() => {
      addPromotion(navigate, promotion);
    });
  }

  const contentType = response.headers.get("content-type");
  if (!contentType || !contentType.includes("application/json")) {
    throw new Error("Unexpected response format");
  }

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Error while saving new promotion");
  }

  return await response.json();
}
