import DrawerBar from "../Shared/DrawerBar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import InterventionTable from "./InterventionsTable";
import ProfilBox from "./Profil";
import UpdatePassword from "./UpdatePassword";
import "../../style/Home.css";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Autocomplete, TextField } from "@mui/material";
import { fetchAllUsers } from "../Shared/CallAPI/Fetch/fetchAllUsers";
import { styled, lighten, darken } from "@mui/material/styles";
import { fetchAnnees } from "../Shared/CallAPI/Fetch/fetchAnnees";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles("dark", {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function Home() {
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUserIsCurrentUser, setSelectedUserIsCurrentUser] =
    useState(true);

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const isDirectorOrAdmin =
    currentUser.role.nom === "Directeur" ||
    currentUser.role.nom === "Administration";

  const [selectedUser, setSelectedUser] = useState(currentUser);

  useEffect(() => {
    // Fetch the school years
    fetchAnnees()
      .then((data) => {
        if (data === null) {
          return;
        }

        setSchoolYears(data);

        // Set selected school year to the current year by default
        const currentYear = data.find(
          (year) => year.currentAnneeScolaire === true,
        );
        setSelectedSchoolYear(currentYear);
      })
      .catch((error) =>
        toast.error("Erreur lors de la récupération des années scolaires"),
      );

    // Only if the current user is director or administration
    if (isDirectorOrAdmin) {
      fetchAllUsers().then((data) => {
        if (data === null) {
          return;
        }
        setUsers(data);
      });
    }
  }, []);

  return (
    <Box className="home-container">
      <DrawerBar selected={"/home"} />
      <Box className="home-main">
        <Box className="title-and-selector-container">
          <Autocomplete
            size="small"
            disableClearable
            className="select-school-year"
            options={schoolYears}
            getOptionLabel={(option) => option.anneeScolaire ?? ""}
            defaultValue={selectedSchoolYear}
            renderInput={(params) => (
              <TextField {...params} label="Année scolaire" />
            )}
            value={selectedSchoolYear}
            onChange={(event, value) => setSelectedSchoolYear(value)}
          />
          <Typography className="home-title" variant="h4">
            Interventions effectuées <br />
            <Typography
              component="span"
              variant="h4"
              style={{ fontWeight: 300 }}
            >
              {!selectedUserIsCurrentUser
                ? `${selectedUser.surname} ${selectedUser.name} `
                : null}
              ({selectedSchoolYear?.anneeScolaire})
            </Typography>
          </Typography>
        </Box>
        <InterventionTable
          className="table-container"
          elevation={5}
          selectedSchoolYear={selectedSchoolYear}
          selectedUser={selectedUser}
        />
        <Box className="profile-settings-container">
          <ProfilBox selectedUser={selectedUser} />

          {/* The user can choose any user of the db to see his interventions */}
          {isDirectorOrAdmin && (
            <Autocomplete
              size="small"
              sx={{ width: 200 }}
              disableClearable
              options={users
                .slice() // Copy the array to avoid mutating the original
                .sort((a, b) => {
                  const surnameComparison = a.surname.localeCompare(b.surname);
                  if (surnameComparison !== 0) {
                    return surnameComparison; // Sort by surname
                  }
                  return a.name.localeCompare(b.name); // Then by name
                })}
              groupBy={(option) => option.surname[0].toUpperCase()} // Group by first letter of surname
              getOptionLabel={(option) => `${option.surname} ${option.name}`}
              // Render the input field
              renderInput={(params) => (
                <TextField {...params} label="Intervenants" />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              value={selectedUser}
              onChange={(event, value) => {
                setSelectedUser(value);
                setSelectedUserIsCurrentUser(value.id === currentUser.id);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id} // To compare objects
            />
          )}
          <UpdatePassword
            selectedUserIsCurrentUser={selectedUserIsCurrentUser}
          />
        </Box>
      </Box>
    </Box>
  );
}
