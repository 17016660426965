import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { Autocomplete, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import { useState } from "react";
import { createAccount } from "../Intervenants/api_requests/createAccount";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import toast from "react-hot-toast";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "55%",
    maxWidth: "none",
  },
}));

export function DialogAddIntervenant(props) {
  const { roles, types, open, handleClose, newUserReload } = props;
  const [phoneNotValid, setPhoneNotValid] = useState(false);
  const [mailNotValid, setMailNotValid] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [typeName, setTypeName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const currentUserRole = JSON.parse(localStorage.getItem("user")).role.nom;

  const newAccount = () => {
    setAllFieldsFilled(false);

    setMailNotValid(false);
    setPhoneNotValid(false);

    // Verify that all fields are filled
    if (
      name === "" ||
      surname === "" ||
      mail === "" ||
      !typeName ||
      (currentUserRole === "Directeur" && !roleName)
    ) {
      setAllFieldsFilled(true);
      return;
    }

    // Verify that the email is valid
    if (!mail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setMailNotValid(true);
      return;
    }

    // Verify that the phone number is valid
    if (phoneNumber !== "" && !phoneNumber.match(/^[0-9]{10}$/)) {
      console.log(phoneNumber)
      setPhoneNotValid(true);
      return;
    }

    const userToAdd = {
      name: name,
      surname: surname,
      mail: mail,
      phoneNumber: phoneNumber,
      typeName: typeName,
      role: currentUserRole === "Directeur" ? roleName : null, // Only the director can add a role, else it's null
    };

    const promise = createAccount(userToAdd).then((msg) => {
      // Reset fields
      setName("");
      setSurname("");
      setMail("");
      setPhoneNumber("");
      setTypeName("");
      newUserReload();
      return msg.success;
    });
    handleClose();

    toast.promise(promise, {
      loading: "Création du compte...",
      success: (msg) => <b>{msg}</b>,
      error: (msg) => <b>{msg}</b>,
    });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Typography variant="h6">
          <b>Ajouter un nouvel utilisateur à PlanINSA</b>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button-dialog"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className="creation-user-dialog-content">
        <div className="name-surname-dialog-div">
          <TextField
            sx={{ width: "50%" }}
            label="Prénom"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            sx={{ width: "50%" }}
            label="Nom"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </div>

        <TextField
          label="Email"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
        />
        {mailNotValid && (
          <Typography variant="body2" color="error">
            Veuillez entrer une adresse mail valide
          </Typography>
        )}

        <TextField
          label="Téléphone (facultatif)"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        {phoneNotValid && (
          <Typography variant="body2" color="error">
            Veuillez entrer un numéro de téléphone valide
          </Typography>
        )}

        <Autocomplete
            options={types}
            getOptionLabel={(option) => option ? option : ""}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => <TextField {...params} label="Type" />}
            value={typeName || null}
            onChange={(event, newValue) => {
              setTypeName(newValue || null)
            }}
        />

        {currentUserRole === "Directeur" && (
            <Autocomplete
                options={roles}
                getOptionLabel={(option) => option ? option.nomRole : ""}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Role" />}
                value={roles.find(role => role.nomRole === roleName) || null} 
                onChange={(event, newValue) => {
                  setRoleName(newValue ? newValue.nomRole : null);
                }}
            />
        )}
        
        {allFieldsFilled && (
          <Typography variant="body2" color="error">
            Veuillez remplir tous les champs
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus color="error" onClick={handleClose}>
          Annuler
        </Button>
        <Button autoFocus onClick={newAccount}>
          Valider
        </Button>{" "}
        {/* Utiliser la fonction renommée */}
      </DialogActions>
    </BootstrapDialog>
  );
}
