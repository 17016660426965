import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";

export async function updateDomaine(navigate, id, newName, idResponsable) {
    return new Promise(async (resolve, reject) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const body = {
            "Domaine": {
                "id": id,
                "newName": newName
            },
            "Responsable": {
                "id": idResponsable
            }
        };
        try {
            const response = await fetch(`${apiUrl}/api/updateResponsableAndNameDomaine`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(body),
            });

            // Handling token expiration or invalidation
            if (response.status === 401) {
                console.log("Token invalide");
                await verifyToken(toast, navigate).then(() => {
                    updateDomaine(navigate, id, newName, idResponsable).then(resolve).catch(reject);
                });
                return;
            }

            // Unauthorized access
            if (response.status === 403) {
                reject("Vous n'êtes pas autorisé à mettre à jour ce domaine.");
                return;
            }

            // Missing or invalid input fields
            if (response.status === 400) {
                const error = await response.json();
                reject(error.error || "Tous les champs requis doivent être remplis.");
                return;
            }

            // Internal server error
            if (response.status === 500) {
                reject("Erreur interne du serveur lors de la mise à jour du domaine.");
                return;
            }

            // Specific gateway errors
            if (response.status === 502) {
                reject("Erreur de passerelle : une erreur est survenue lors de la mise à jour du domaine.");
                return;
            }

            // Successful update
            if (response.ok) {
                resolve(`Le domaine "${newName}" a été mis à jour avec succès.`);
            } else {
                reject("Une erreur s'est produite lors de la mise à jour du domaine.");
            }
        } catch (error) {
            // Catching generic errors
            reject(error.message || "Une erreur s'est produite lors de la requête.");
        }
    });
}
