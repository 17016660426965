import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;

export async function fetchFiltersFiche(navigate, filters) {
    return new Promise(async (resolve, reject) => {
        try {
            const req = await fetch(`${apiUrl}/api/getDashBoardFiche`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(filters),
            });
            // If the token is invalid
            const result = await handleApiResponse(
                req,
                () => fetchFiltersFiche(navigate, filters),
                true
            );
            resolve(result);
        } catch (error) {
            // Handle any other errors
            reject(error.message);
        }
    });
}

