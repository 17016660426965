import * as React from "react";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateDateMax } from "./api_requests/updateDateMax";
import { fetchDateMax } from "../Shared/CallAPI/Fetch/fetchDateMax";
import { fetchCurrentSchoolYear } from "../Shared/CallAPI/Fetch/fetchCurrentSchoolYear";
import { fetchAnnees } from "../Shared/CallAPI/Fetch/fetchAnnees";
import { updateCurrentSchoolYear } from "./api_requests/updateCurrentSchoolYear"; // Import the method
import dayjs from "dayjs";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select"; // Import Select component
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem component

import "../../style/Variables.css";
import {addNewSchoolYear} from "./api_requests/addNewSchoolYear";

export default function InputDateAndYears({currentSchoolYear, setCurrentSchoolYear}) {
    const [newDateMax, setNewDateMax] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openNewYearConfirmDialog, setOpenNewYearConfirmDialog] =
        useState(false);
    const [schoolYears, setSchoolYears] = useState([]);
    
    const [nextSchoolYear, setNextSchoolYear] = useState(null);
    const [enableNextYearButton, setEnableNextYearButton] = useState(true);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState(''); // State for selected school year
    const navigate = useNavigate();

    // Check if the next school year already exists in the list of school years
    const enabledNextYearButton = () => {
        const nextYearExists = schoolYears.some((item) => item.anneeScolaire === nextSchoolYear);
        setEnableNextYearButton(!nextYearExists);
    };

    const defineNextSchoolYear = (currentSchoolYear) => {
        const currentYear = parseInt(currentSchoolYear.split("-")[1]);
        const nextYear = currentYear + 1;
        setNextSchoolYear(`${currentYear}-${nextYear}`);
    };

    useEffect(() => {
        fetchDateMax()
            .then((response) => {
                setNewDateMax(dayjs(response.dateMax));
            })
            .catch((error) => {
                console.log(error);
            });

        

        fetchAnnees()
            .then((response) => {
                setSchoolYears(response);
            })
            .catch((error) => {
                    console.log(error);
                }
            );
    }, [navigate]);

    useEffect(() => {
        if (currentSchoolYear) {
            defineNextSchoolYear(currentSchoolYear);
        }
    }, [currentSchoolYear]);

    useEffect(() => {
        enabledNextYearButton();
    }, [schoolYears, nextSchoolYear]);

    // Handle selection of a school year from the Select dropdown
    const handleSelectSchoolYear = (event) => {
        const selectedYear = event.target.value;
        setSelectedSchoolYear(selectedYear);

        const promise = updateCurrentSchoolYear(navigate, selectedYear)
            .then((msg) => {
                setCurrentSchoolYear(selectedYear);
                return msg;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
        
        toast.promise(promise, {
            loading: "Mise à jour en cours...",
            success: (msg) => msg,
            error: (msg) => msg
        });
    };

    const updateNewYear = () => {
        setOpenNewYearConfirmDialog(true);
    };

    const handleConfirmDialogOpen = () => {
        setOpenConfirmDialog(true);
    };

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
    };

    const handleNewYearConfirmDialogClose = () => {
        setOpenNewYearConfirmDialog(false);
    };

    const handleConfirmUpdateDateMax = async () => {
        const promise = updateDateMax(navigate, newDateMax)
            .then((response) => {
                setNewDateMax(newDateMax); // Update the date displayed
                setOpenConfirmDialog(false);
                return response;
            })
            .catch((error) => {
                return error;
            });

        toast.promise(promise, {
            loading: "Mise à jour en cours...",
            success: (msg) => msg,
            error: (msg) => msg,
        });
    };

    const handleNewYearConfirm = async () => {
        const promise = addNewSchoolYear(navigate, nextSchoolYear)
            .then((response) => {
                // Add the new school year to the list of school years
                setSchoolYears((prevYears) => [{ anneeScolaire: nextSchoolYear }, ...prevYears]);
                setCurrentSchoolYear(nextSchoolYear);
                setOpenNewYearConfirmDialog(false);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });

        toast.promise(promise, {
            loading: "Passage à l'année suivante en cours...",
            success: (msg) => msg,
            error: (msg) => msg,
        });
    };

    return (
        <Box className="Dates-container">
            <Box className="Date-max-container">
                <Typography variant="h6">
                    Date limite de dépôt
                </Typography>
                <Box className="Date-picker-button-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {newDateMax !== null ? (
                            <DatePicker
                                value={newDateMax}
                                format="DD/MM/YYYY"
                                minDate={dayjs()} // The date must be greater than or equal to today
                                onChange={(newDate) => setNewDateMax(newDate)}
                            />
                        ) : (
                            <DatePicker
                                label="Date limite de dépôt"
                                //minDate={dayjs()}
                                onChange={(newDate) => setNewDateMax(newDate)}
                            />
                        )}
                    </LocalizationProvider>
                    <Button
                        onClick={handleConfirmDialogOpen}
                        variant="contained"
                        className="button-save-date-max"
                    >
                        Confirmer
                    </Button>
                </Box>
            </Box>

            {/* Select dropdown for selecting school years */}
            <Box className="School-year-select-container" sx={{ marginTop: 2 }}>
                <Typography variant="h6">
                    Changer d'année scolaire
                </Typography>
                <Select
                    value={currentSchoolYear}
                    onChange={handleSelectSchoolYear}
                    fullWidth
                >
                    {schoolYears.map((year) => (
                        <MenuItem key={year.id} value={year.anneeScolaire}>
                            {year.anneeScolaire}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box className="New-year-container">
                <Typography variant="h6">
                    Nouvelle année scolaire ?
                </Typography>

                {/* Tooltip only appears if the button is disabled */}
                <Tooltip
                    title={"Vous devez définir l'année actuelle à la dernière année possible dans la liste" +
                        " pour pouvoir passer à l'année scolaire suivante"
                    }
                    arrow
                    disableHoverListener={enableNextYearButton} // Disable tooltip if the button is enabled
                >
                    <span> {/* Wrapping the button in a span to ensure tooltip works with disabled buttons */}
                        <Button
                            onClick={updateNewYear}
                            variant="contained"
                            disabled={!enableNextYearButton}
                        >
                            Passer à l'année suivante
                        </Button>
                    </span>
                </Tooltip>
            </Box>

            {/* Dialog for the confirmation of the date update */}
            <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
                <DialogTitle>Confirmer la mise à jour</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir mettre à jour la date limite de dépôt au{" "}
                        <strong>{newDateMax ? newDateMax.format("DD/MM/YYYY") : ""}</strong> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose}>Annuler</Button>
                    <Button onClick={handleConfirmUpdateDateMax} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for the confirmation of the new school year */}
            <Dialog
                open={openNewYearConfirmDialog}
                onClose={handleNewYearConfirmDialogClose}
            >
                <DialogTitle>Passer à l'année suivante</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir passer à l'année scolaire suivante : {" "}
                        <strong>{nextSchoolYear}</strong> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewYearConfirmDialogClose}>Annuler</Button>
                    <Button onClick={handleNewYearConfirm} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
