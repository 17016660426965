import DrawerBar from "../Shared/DrawerBar";
import * as React from "react";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../../style/Variables.css";
import InputDateAndYears from "./InputDateAndYears.jsx";
import PromotionGrid from "./PromotionGrid";
import ParcoursGrid from "./ParcoursGrid";
import SemestreGrid from "./SemestreGrid";
import DomaineGrid from "./DomaineGrid";
import ImportStudentButton from "../Shared/ImportStudentButton";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {fetchCurrentSchoolYear} from "../Shared/CallAPI/Fetch/fetchCurrentSchoolYear";

export default function Variables() {
  const navigate = useNavigate();
    const [currentSchoolYear, setCurrentSchoolYear] = useState(null);

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    //if the user isn't Directeur
    if (role.nom !== "Directeur") {
      toast.error("Vous n'avez pas accès à cette page");
      navigate("/home");
    }
  fetchCurrentSchoolYear()
      .then((response) => {
          setCurrentSchoolYear(response[0].anneeScolaire);
      })
      .catch((error) => {
          console.log(error);
      });
  }, [navigate]);

  return (
    <Box className="variables-page">
      <DrawerBar selected={"/variables"} />
      <InputDateAndYears currentSchoolYear = {currentSchoolYear} setCurrentSchoolYear={setCurrentSchoolYear} />

        <Box sx = {{display : 'flex', flexDirection : 'column', alignItems: "center",width : "100%"}}>
            <Container className="grid-variables-container">
                <PromotionGrid />
                <ParcoursGrid />
                <SemestreGrid/>
                <DomaineGrid />
            </Container>
            <Box sx = {{marginTop : "10vh", display : 'flex', flexDirection : 'column', alignItems: "center", width : "60%"}}>
                <Typography variant="h5" sx={{ marginBottom: '2vh' }}>
                    Importer des étudiants pour les fiches tutorées ({currentSchoolYear})
                </Typography>
                <ImportStudentButton year={currentSchoolYear}/>
            </Box>
        </Box>
    </Box>
  );
}
