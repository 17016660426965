import React from "react";
import "../../style/Comptes.css";
import Box from "@mui/material/Box";

function getColor(value) {
  //value from 0 to 1
  const hue = ((1 - value / 100) * 120).toString(10);
  return ["hsl(", hue, ",100%,50%,70%)"].join("");
}

export default function PercentCell({ value, type, totalHours }) {
  const displayValue =
    type === 1
      ? totalHours > 0
        ? Math.round((value / totalHours) * 100)
        : 0
      : value;
  const backgroundColor = type === 1 ? getColor(displayValue) : null;

  return (
    <Box
      className="percent-cell"
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {displayValue}
      {type === 1 ? "%" : ""}
    </Box>
  );
}
