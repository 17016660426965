import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function updateFicheProjetTutore(fiche) {
  return new Promise(async (resolve, reject) => {
  try {
    const req = await fetch(`${apiUrl}/api/updateFicheProjetTutore`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(fiche),
    });
    // If the token is invalid
    const result = await handleApiResponse(
        req,
        () => updateFicheProjetTutore(fiche),
        false
    );
    resolve(result);
  } catch (error) {
    // Handle any other errors
    reject(error.message);
  }
});
}
