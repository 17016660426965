import * as React from "react";
import Box from "@mui/material/Box";
import DrawerBar from "../Shared/DrawerBar";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CompteGridActivite from "./CompteGridActivite";
import { useNavigate } from "react-router-dom";

import "../../style/Comptes.css";

export default function Comptes() {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    //if the user isn't Directeur
    if (role.nom !== "Directeur") {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
    }
  }, [navigate]);
  return (
      <Box className = 'comptes'>
        <DrawerBar selected={"/comptes"} />
        <Box className = 'container-navbar-grid'>
          <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="data grid tabs"
          >
            <Tab label="Activité EqTD" />
            <Tab label="Activité FaF" />
          </Tabs>

          <Box className="grid-container">
            {tabIndex === 0 && <CompteGridActivite typeOfTotal="EqTD"/>}
            {tabIndex === 1 && <CompteGridActivite typeOfTotal="FaF" />}
          </Box>
        </Box>
      </Box>
  );
}
